import { Button, Spinner, Switch } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getCollectionById } from '../../utils/collectionUtils'
import { getRoles } from '../../utils/rolesUtils'
import { InputDefault } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'
import { RolePerAttibute } from './_components/RolePerAttibute'
import { RolePerQty } from './_components/RolePerQty'

const EditHolderVerification = () => {
  const { id } = useParams()
  const [collectionData, setCollectionData] = useState(null)
  const [roles, setRoles] = useState([])
  const [rolesPerAtt, setRolesPerAtt] = useState([])
  const [rolesPerQty, setRolesPerQty] = useState([])
  const [listing, setListing] = useState(false)
  const [sales, setSales] = useState(false)
  const navigate = useNavigate()
  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  const initialHolderRole =
    roleOptions.find((option) => option.value === collectionData?.HolderRole) ||
    null
  const [holderRole, setHolderRole] = useState(initialHolderRole)

  useEffect(() => {
    if (collectionData) {
      setListing(collectionData.Listing?.IsEnabled || false)
    }
  }, [collectionData])

  useEffect(() => {
    if (collectionData) {
      setSales(collectionData.Sales?.IsEnabled || false)
    }
  }, [collectionData])

  // load roles
  const loadRoles = async () => {
    const data = await getRoles()
    setRoles(data)
  }

  useEffect(() => {
    if (roles.length === 0) {
      loadRoles()
    }
  }, [])

  useEffect(() => {
    getCollectionById(id)
      .then((data) => {
        setCollectionData(data)
      })
      .catch((error) => {
        console.error('Error fetching collection data:', error)
      })
  }, [id])

  const handleRoleChange = (selectedOption) => {
    setHolderRole(selectedOption)
  }

  const handleRolesPerAttUpdate = (updatedRolesPerAtt) => {
    setRolesPerAtt(updatedRolesPerAtt)
  }

  const handleRolesPerQtyUpdate = (updatedRolesPerQty) => {
    setRolesPerQty(updatedRolesPerQty)
  }

  const saveClick = async () => {
    const updateCollection = {
      DisplayName: collectionData.DisplayName,
      VerifiedAddress: collectionData.VerifiedAddress,
      CollectionMint: collectionData.CollectionMint,
      HolderRole:
        holderRole === null ? initialHolderRole.value : holderRole.value,
      RolePerQuantity: rolesPerQty.map((role) => ({
        roleId: role.RoleId,
        roleName: role.RoleName,
        numberOfNfts: role.NumberOfNfts,
      })),
      RolePerAttribute: rolesPerAtt.map((role) => ({
        roleId: role.RoleId,
        roleName: role.RoleName,
        attributeName: role.AttributeName,
        attributeValue: role.AttributeValue,
      })),
      ...(collectionData.IsToken === false && {
        Sales: {
          IsEnabled: sales,
        },
        Listing: {
          IsEnabled: listing,
        },
      }),
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/collections/notification-info?guildId=${localStorage.getItem('selectedServer')}&collectionId=${id}`
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateCollection),
      })
      if (!response.ok) {
        throw new Error('Failed to update collections')
      }
      const data = await response.json()
      toast.success('Collection updated successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error updating collections:', error)
      toast.error(`Opss, something gone wrong: ${error.message}`)
    }
  }

  const handleSalesChange = () => {
    setSales(!sales)
  }

  const handleListingChange = () => {
    setListing(!listing)
  }

  return (
    <>
      {collectionData ? (
        <section className="flex w-full justify-center text-white py-2 sm:py-5 relative h-full">
          <div className="container mx-auto w-full max-w-[1300px]">
            {/* header */}
            <div className="flex justify-between items-center pb-10">
              <h1 className="text-2xl font-bold h-full flex items-end">
                {collectionData
                  ? `Edit ${collectionData.IsToken ? 'Token' : 'NFT'} Collection`
                  : 'Loading...'}
              </h1>
              <Button
                color="deep-purple"
                className="bg-main hover:bg-mainHover w-20 h-full max-h-[52px] flex text-center items-center justify-center"
                onClick={() => navigate(-1)}
              >
                <BiLeftArrowAlt size={18} />
              </Button>
            </div>
            {/* body */}
            {/* info collection */}
            <div className="flex flex-col lg:flex-row gap-5 w-full">
              <div className="flex flex-col gap-3 w-full lg:max-w-[400px]">
                {/* Collection Name */}
                <InputDefault
                  value={collectionData.DisplayName || collectionData.CollectionName || collectionData.CollectionDisplayName}
                  label="Collection Name"
                  placeholder="Example: My Collection."
                  readOnly={true}
                />
                <SelectDefault
                  options={roleOptions}
                  label="Holder Role"
                  value={holderRole === null ? initialHolderRole : holderRole}
                  onChange={handleRoleChange}
                />
                {/* Sales and Listings */}
                {collectionData.IsToken === false && (
                  <div className="w-full  flex gap-x-5 justify-end">
                    <Switch
                      label="Sales"
                      color="purple"
                      checked={sales}
                      onChange={handleSalesChange}
                    />
                    <Switch
                      label="Listings"
                      color="purple"
                      checked={listing}
                      onChange={handleListingChange}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3 w-full">
                {/* Collection Address */}
                {collectionData.CollectionMint && (
                  <InputDefault
                    value={collectionData.CollectionMint}
                    label="Collection Address"
                    readOnly={true}
                  />
                )}
                {/* Creator Address */}
                {collectionData.VerifiedAddress && (
                  <InputDefault
                    value={collectionData.VerifiedAddress}
                    label="Creator Address"
                    readOnly={true}
                  />
                )}
                {/* Token Address */}
                {collectionData.TokenAddress && (
                  <InputDefault
                    value={collectionData.TokenAddress}
                    label="Token Address"
                    readOnly={true}
                  />
                )}
              </div>
            </div>
            <hr className="w-full border-third my-10" />
            {/* section 2 */}
            <div className="flex flex-col gap-y-5 w-full">
              <RolePerQty
                RoleQuantity={collectionData.RolePerQuantity}
                listRoleQuantity={handleRolesPerQtyUpdate}
              />
              {collectionData.IsToken === false && (
                <>
                  <hr className="w-full border-third my-10" />
                  <RolePerAttibute
                    RoleAttribute={collectionData.RolePerAttribute}
                    creatorAddress={collectionData.VerifiedAddress}
                    collectionAddress={collectionData.CollectionMint}
                    listRoleAttribute={handleRolesPerAttUpdate}
                  />
                </>
              )}
            </div>
            <hr className="w-full border-third my-10" />
            <div className="flex justify-end pb-20">
              <Button
                onClick={saveClick}
                className="bg-main w-32 h-12"
                color="purple"
              >
                Save
              </Button>
            </div>
          </div>
        </section>
      ) : (
        // Loading Spinner
        <div className="h-full w-full flex justify-center items-center">
          <Spinner className="w-12 h-12 text-main/50" />
        </div>
      )}
      <Toaster
        position="top-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
    </>
  )
}

export default EditHolderVerification
