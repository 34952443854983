import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from '@material-tailwind/react'
import React, { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'

const CreateWallet = ({ onWalletCreated }) => {
  const [walletName, setWalletName] = useState('')
  const [walletInfo, setWalletInfo] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    setWalletName('')
    setWalletInfo('')
  }

  const handleCreateWallet = async () => {
    setLoading(true)
    const errors = []
    if (!walletName) {
      errors.push('Please fill in all fields')
    }
    if (!walletInfo) {
      errors.push('Please fill in all fields')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }
    const infoWallet = {
      guildId: localStorage.getItem('guildId'),
      walletName: walletName.trim(), // Remover espaços em branco
      info: walletInfo.trim(),
    }

    // Verifique se os dados são válidos
    if (!infoWallet.guildId || !infoWallet.walletName || !infoWallet.info) {
      console.error('Dados inválidos:', infoWallet)
      toast.error('Dados inválidos. Por favor, verifique os campos.')
      setLoading(false)
      return
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/wallets`
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoWallet),
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const text = await response.text()
      const data = text ? JSON.parse(text) : {}
      console.log('Wallet data received:', data)
      toast.success('Wallet created successfully!')
      onWalletCreated()
      handleCancel()
      setLoading(false)
    } catch (error) {
      console.error('Fetch error:', error)
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <>
      <Toaster
        position="bottom-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
      <section className="flex justify-center items-center">
        <Card className="w-full max-w-[900px] h-full bg-basic p-5 text-white">
          <CardHeader className="bg-third p-2 flex items-center justify-center text-white font-semibold uppercase tracking-wide">
            Create new Wallet
          </CardHeader>
          <CardBody className="flex flex-col gap-y-5">
            {/* wallet name */}
            <div className="w-full lg:flex flex-1 justify-end">
              <span className="flex lg:w-44 items-end  font-semibold">
                Wallet Name
              </span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Input
                  placeholder="Enter the wallet name here*"
                  color="deep-purple"
                  variant="static"
                  className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                  size="lg"
                  containerProps={{
                    className: 'bg-second ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                    style: {
                      color: 'white',
                    },
                  }}
                  value={walletName}
                  onChange={(e) => setWalletName(e.target.value)}
                />
              </div>
            </div>
            {/* Wallet info */}
            <div className="w-full lg:flex flex-1 justify-end">
              <span className="flex lg:w-44 items-end  font-semibold">
                Wallet Info
              </span>
              <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                <Input
                  placeholder="Enter the wallet info here*"
                  color="deep-purple"
                  variant="static"
                  className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                  size="lg"
                  containerProps={{
                    className: 'bg-second ',
                  }}
                  labelProps={{
                    className:
                      'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                    style: {
                      color: 'white',
                    },
                  }}
                  value={walletInfo}
                  onChange={(e) => setWalletInfo(e.target.value)}
                />
              </div>
            </div>
          </CardBody>
          <CardFooter className="flex justify-end py-0">
            <Button variant="text" color="red" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              variant="filled"
              color="purple"
              onClick={() => handleCreateWallet()}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create'}
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  )
}

export default CreateWallet
