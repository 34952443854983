import { Button, Input, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { toast } from 'react-toastify'
import AddTrait from './_components/AddTrait'
import { SelectDefault } from '../components/ui/select'
import { InputDefault } from '../components/ui/input'

const CreateRevenueSharing = ({ loadRevSharing, returnBotton }) => {
  const [isTraitTrue, setIsTraitTrue] = useState(false)
  const [nameToken, setNameToken] = useState('')
  const [amountPerNft, setAmountPerNft] = useState('')
  const [minimumStakedDays, setMinimumStakedDays] = useState('')
  const [endsInDays, setEndsInDays] = useState('')
  const [traitRewards, setTraitRewards] = useState([])
  const [listRevSharing, setListRevSharing] = useState([])
  const [collectionLists, setCollectionLists] = useState([])
  const [collectionSelected, setCollectionSelected] = useState('')

  const infoRevenueSharing = {
    GuildId: localStorage.getItem('selectedServer'),
    GuildName: listRevSharing ? listRevSharing.GuildName : '',
    TokenName: nameToken,
    CollectionVerifiedAddress: collectionSelected.VerifiedAddress,
    CollectionId: collectionSelected.Id,
    AmountPerNft: amountPerNft,
    MinimumStakedDays: minimumStakedDays,
    CreatedDate: new Date().toISOString(),
    ExpireDate: new Date(
      new Date().getTime() + endsInDays * 24 * 60 * 60 * 1000
    ).toISOString(),
    TraitRewards: isTraitTrue ? traitRewards : [],
  }

  useEffect(() => {
    const fetchCollectionList = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/collections/by-guild/${localStorage.getItem('guildId')}`
      )
      const data = await response.json()
      setCollectionLists(data)
    }
    fetchCollectionList()
  }, [])

  useEffect(() => {
    setListRevSharing(loadRevSharing)
  }, [loadRevSharing])

  const saveRevenueSharing = async () => {
    if (
      collectionSelected &&
      amountPerNft.trim() &&
      nameToken.trim() &&
      minimumStakedDays !== '' &&
      endsInDays !== ''
    ) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/revenue-sharing`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(infoRevenueSharing),
        }
      )
      if (response.ok) {
        console.log('Revenue sharing saved successfully', response)
      } else {
        console.error('Failed to save revenue sharing', response)
      }
    } else {
      toast.error('Please fill in all the fields')
    }
  }

  const handleCollectionChange = (selectedId) => {
    const selectedCollection = collectionLists?.find(
      (collection) => collection.Id === selectedId
    )
    setCollectionSelected(selectedCollection)
  }

  return (
    <>
      <section className=" text-white flex justify-center">
        <div className="flex flex-col gap-y-5 w-[1000px]">
          {/* Name of your token */}
          <div className="flex gap-x-5">
            {/* nameToken */}
            <InputDefault
              label="Enter the name of your token*"
              placeholder="Example: SOL."
              onChange={(e) => setNameToken(e.target.value)}
              value={nameToken}
            />
            {/* Amount per NFT */}
            <InputDefault
              label="Amount per NFT*"
              placeholder="Example: 0.05"
              onChange={(e) => setAmountPerNft(e.target.value)}
              value={amountPerNft}
            />
          </div>
          <div className="flex gap-x-5">
            {/* Collection */}
            <SelectDefault
              label="Collection address that will receive revenue*"
              onChange={(value) => handleCollectionChange(value)}
            />
            {/* Minimum staked days */}
            <div className="flex w-full gap-x-5">
              <SelectDefault
                label="Minimum staked days*"
                onChange={(value) => setMinimumStakedDays(value)}
                options={[
                  { value: 0, label: '0' },
                  { value: 7, label: '7' },
                ]}
              />
              {/* Ends in days */}
              <InputDefault
                label="Ends is days*"
                placeholder="Example: 2"
                onChange={(e) => setEndsInDays(e.target.value)}
                value={endsInDays}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-10 ">

            <div className="flex w-full gap-x-5 justify-end">
              <div className="text-sm font-bold">
                {isTraitTrue ? 'Disable' : 'Enable'} trait rewards
              </div>
              <Switch
                color="deep-purple"
                className="ml-auto"
                onChange={(e) => setIsTraitTrue(e.target.checked)}
              />
            </div>
            {isTraitTrue && (
              <AddTrait
                creatorAddress={collectionSelected.VerifiedAddress}
                onTraitRewardsChange={setTraitRewards}
              />
            )}
          </div>

          {/* Button section */}
          <hr className="h-px my-5 bg-gray border-0" />
          <div className="flex justify-between">
            <Button
              className="bg-third text-white px-14 shadow-md shadow-shadow hover:bg-basic"
              onClick={() => {
                setNameToken('')
                setAmountPerNft('')
                setMinimumStakedDays('')
                setEndsInDays('')
                setIsTraitTrue(false)
                setTraitRewards([])
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-accentMain text-white px-10 shadow-md shadow-shadow hover:bg-accentHouver"
              onClick={saveRevenueSharing}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateRevenueSharing
