import { Drawer, IconButton } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Sidebar from './Sidebar'

const MobileSidebar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  return (
    <>
      <IconButton variant="text" size="lg" onClick={openDrawer}>
        <FaBars size={28} className="text-main" />
      </IconButton>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="bg-bgAdmin">
        <Sidebar />
        <div className="fixed top-4 right-4">
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <FaTimes size={28} className="text-main" />
          </IconButton>
        </div>
      </Drawer>
    </>
  )
}

export default MobileSidebar
