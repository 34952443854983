import { Button, Switch } from '@material-tailwind/react'
import React, { useState, useEffect, KeyboardEventHandler } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'
import { ToastContainer, toast } from 'react-toastify'
import MultiselectChannel from '../components/multiselectChannel'
import MultiselectRole from '../components/multiselectRole'

const CreateVotingSystem = () => {
  const animatedComponents = makeAnimated()
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pollOptions, setPollOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState([])
  const [question, setQuestion] = useState('')
  const [checked, setChecked] = useState(false)

  const components = {
    DropdownIndicator: null,
  }

  const createOption = (label) => ({
    label,
    value: label,
  })

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)])
        setInputValue('')
        event.preventDefault()
    }
  }

  //chamada API para listar as Roles
  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true)
      try {
        const apiUrlSelectRole = `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
        const response = await fetch(apiUrlSelectRole)
        const data = await response.json()
        setRoles(data)
      } catch (error) {
        console.error('Erro ao buscar roles:', error)
        // Lide com o erro de acordo com a sua aplicação
      } finally {
        setIsLoading(false)
      }
    }
    if (roles.length === 0) {
      fetchRoles()
    }
  }, [])

  const dropdownOptionsRole = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  const handleSelectRole = (selectedOptions) => {
    setSelectedRoles(selectedOptions)
  }

  //chamada API para listar as Channels
  useEffect(() => {
    const fetchChannels = async () => {
      setIsLoading(true)
      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/channels/${localStorage.getItem('selectedServer')}`
        // const apiUrl = `/channels/${localStorage.getItem('selectedServer')}`
        const response = await fetch(apiUrl)
        const data = await response.json()
        setChannels(data)
      } catch (error) {
        console.error('Erro ao buscar roles:', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (channels.length === 0) {
      fetchChannels()
    }
  }, [])

  const dropdownOptionsChannel = channels.map((channel) => ({
    value: channel.id,
    label: channel.name,
  }))

  //seleciona multiselect
  const handleSelectChannel = (selectedOptions) => {
    if (selectedOptions.length > 1) {
      selectedOptions = selectedOptions.slice(0, 1)
    }
    setSelectedChannel(selectedOptions)
  }

  //quantity reward
  const [valuePollDuration, setValuePollDuration] = useState(1)
  const incrementPollDuration = () => {
    setValuePollDuration((prevValue) => prevValue + 1)
  }
  const decrementPollDuration = () => {
    setValuePollDuration((prevValue) => (prevValue <= 1 ? 1 : prevValue - 1))
  }

  //create poll
  const handleCreatePoll = async () => {
    if (
      selectedRoles.length === 0 ||
      selectedChannel.length === 0 ||
      question === '' ||
      value.length === 0
    ) {
      toast.error('Please fill in all fields.')
      return
    }
    const pollData = {
      guildId: localStorage.getItem('selectedServer'),
      roles: selectedRoles.map((role) => role.value),
      channelId: selectedChannel.length > 0 ? selectedChannel[0].value : null,
      question: question,
      options: value.map((option) => option.value),
      duration: checked ? `${valuePollDuration}d` : `${valuePollDuration}h`,
    }
    console.log(pollData)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/polls`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(pollData),
        }
      )
      if (!response.ok) {
        throw new Error('Erro ao criar a votação')
      }
      const data = await response.json()
      console.log('Votação criada com sucesso:', data)
    } catch (error) {
      console.error('Erro ao criar a votação:', error)
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="container mx-auto flex flex-col gap-y-5 py-20">
        <div className="w-full h-16 bg-dark absolute top-0 left-0"></div>
        <div className="flex gap-x-2">
          {/* Role voting */}
          <div className="w-full flex flex-col justify-start">
            <span className="text-sm">Role for voting</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg border border-third">
              <Select
                components={animatedComponents}
                isMulti={true}
                isClearable
                options={dropdownOptionsRole}
                value={selectedRoles}
                onChange={handleSelectRole}
                placeholder="Select Roles"
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: '#393D5E',
                    color: '#fff',
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                    height: 'auto',
                    maxHeight: '100%',
                    color: '#fff',
                  }),
                  container: (provided, state) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                    border: 0,
                    color: '#fff',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                    color: '#fff',
                    borderRadius: '0px',
                    borderColor:
                      state.isFocused || state.isHovered
                        ? '#9945ff'
                        : '#2C2F48',
                    borderWidth: '0 0 0.5px 0',
                    boxShadow:
                      state.isFocused || state.isHovered
                        ? '0 0 0 0.5px #9945ff'
                        : 'none',
                    paddingLeft: '0.5rem',
                    '&:hover': {
                      borderColor: 'none',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? '#9945ff'
                      : state.isFocused
                        ? '#9945ff'
                        : '#393D5E',
                    color: '#fff',
                    cursor: 'pointer',
                    padding: 0,
                    paddingLeft: '1rem',
                    margin: 0,
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: '#9945ff',
                    color: '#fff',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                }}
              />
            </div>
          </div>
          {/* Channel voting */}
          <div className="w-full flex flex-col justify-start">
            <span className="text-sm">Channel for voting</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg border border-third">
              <Select
                isMulti={true}
                components={animatedComponents}
                isClearable
                options={dropdownOptionsChannel}
                value={selectedChannel}
                onChange={handleSelectChannel}
                placeholder="Select Channel"
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: '#393D5E',
                    color: '#fff',
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                    height: 'auto',
                    maxHeight: '100%',
                    color: '#fff',
                  }),
                  container: (provided, state) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                    border: 0,
                    color: '#fff',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    height: 'auto',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                    color: '#fff',
                    borderRadius: '0px',
                    borderColor:
                      state.isFocused || state.isHovered
                        ? '#9945ff'
                        : '#2C2F48',
                    borderWidth: '0 0 0.5px 0',
                    boxShadow:
                      state.isFocused || state.isHovered
                        ? '0 0 0 0.5px #9945ff'
                        : 'none',
                    paddingLeft: '0.5rem',
                    '&:hover': {
                      borderColor: 'none',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? '#9945ff'
                      : state.isFocused
                        ? '#9945ff'
                        : '#393D5E',
                    color: '#fff',
                    cursor: 'pointer',
                    padding: 0,
                    paddingLeft: '1rem',
                    margin: 0,
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: '#9945ff',
                    color: '#fff',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: '#fff',
                  }),
                }}
              />
            </div>
          </div>
          {/* Duration */}
          <div className="flex flex-col">
            <span className="text-sm">Duration</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg border border-third">
              <div className="flex items-center h-full transition duration-300 rounded-lg shadow  bg-second border-second border ">
                <input
                  type="number"
                  min={1}
                  value={valuePollDuration}
                  onChange={(e) =>
                    setValuePollDuration(parseInt(e.target.value))
                  }
                  className=" h-full text-white p-2 first-of-type:pl-4 border-none bg-transparent w-16 outline-none"
                />
                <span className="text-sm w-12">
                  {checked ? '/days' : '/hours'}
                </span>
                <div className="flex flex-col h-full">
                  <button
                    onClick={incrementPollDuration}
                    className="bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                  >
                    <BiPlus />
                  </button>
                  <hr className="border-second" />
                  <button
                    onClick={decrementPollDuration}
                    className="bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                  >
                    <BiMinus />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-x-5 text-sm pt-0.5">
              <span>Hours</span>
              <Switch
                label="Days"
                className={`${checked ? 'bg-main' : 'bg-accentHouver'}`}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
          </div>
        </div>
        {/* Poll question */}
        <div className="w-full flex flex-col justify-end">
          <span className="text-sm">Poll Question</span>
          <div className="w-full flex flex-col justify-end bg-second rounded border border-third ">
            <textarea
              rows={4}
              placeholder="Example: My Collection."
              className="w-full bg-second text-white border-b-[1px] border-transparent focus:border-mainHover focus:outline-none focus:ring-0 focus:ring-main focus:bg-accentMain/10 pl-5 py-2 placeholder:text-[#808080]"
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
        </div>
        {/* Poll-options */}
        <div className="w-full flex flex-col justify-end">
          <span className="text-sm">Poll Options</span>
          <div className="w-full flex flex-col justify-end bg-second rounded border border-third">
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Type something and press enter..."
              value={value}
              styles={{
                valueContainer: (provided, state) => ({
                  ...provided,
                  padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                  height: 'auto',
                  maxHeight: '100%',
                  color: '#fff',
                }),
                container: (provided, state) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                  border: 0,
                  color: '#fff',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
                control: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                  color: '#fff',
                  borderRadius: '0px',
                  borderColor:
                    state.isFocused || state.isHovered ? '#9945ff' : '#2C2F48',
                  borderWidth: '0 0 0.5px 0',
                  boxShadow:
                    state.isFocused || state.isHovered
                      ? '0 0 0 0.5px #9945ff'
                      : 'none',
                  paddingLeft: '0.5rem',
                  '&:hover': {
                    borderColor: 'none',
                  },
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#9945ff',
                  color: '#fff',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
                input: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
              }}
            />
          </div>
        </div>
        <div className="w-full h-16 bg-dark absolute bottom-0 left-0"></div>
        {/* Button */}
        <div className="flex justify-end absolute bottom-3 right-3">
          <Button
            variant="gradient"
            color="deep-purple"
            onClick={handleCreatePoll}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateVotingSystem
