import React, { useState, useEffect } from 'react'
import { getRoles } from '../../../utils/rolesUtils'
import { InputDefault, InputNumber } from '../../components/ui/input'
import { SelectDefault } from '../../components/ui/select'

const TokenSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onTokenAddress,
  onCollectionName,
  onSkipRole,
}) => {
  const [quantity, setQuantity] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [tokenAddress, setTokenAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [isSkipRole, setIsSkipRole] = useState(false)

  // load roles
  const loadRoles = async () => {
    const data = await getRoles()
    setRoles(data)
  }

  useEffect(() => {
    if (roles.length === 0) {
      loadRoles()
    }
  }, [])

  const roleOptions = roles
    .filter((role) => selectedRole === null || role.id !== selectedRole.value)
    .map((role) => ({
      value: role.id,
      label: role.name,
    }))

  useEffect(() => {
    onQuantitySelect(quantity)
  }, [quantity, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onTokenAddress(tokenAddress)
  }, [tokenAddress, onTokenAddress])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])

  const handleValueQuantity = (value) => {
    setQuantity(value)
  }

  return (
    <>
      <div className="flex flex-col gap-y-5 h-full w-full  max-w-[1300px]">
        {/* Collection Name */}
        <InputDefault
          label="Enter a name of Token*"
          placeholder="Example: My Token."
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
        />
        {/* Address */}
        <InputDefault
          label="Enter a token address*"
          placeholder="Example: 0x1234567890123456789012345678901234567890"
          value={tokenAddress}
          onChange={(e) => setTokenAddress(e.target.value)}
        />
        <div className="w-full flex gap-x-5 justify-end">
          {/* Quantity input token */}
          <InputNumber
            label="Quantity of tokens:"
            value={quantity}
            onChange={handleValueQuantity}
          />
          {/* Quantity input role */}
          <SelectDefault
            options={roleOptions}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
            placeholder="Select Role"
            label="Role"
            value={selectedRole}
          />
        </div>
      </div>
    </>
  )
}

export default TokenSetup
