export const getToken = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/token/${localStorage.getItem('guildId')}`
  )
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }
  const data = await response.json()
  return data
}
