import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { FaCloudUploadAlt, FaTimes } from 'react-icons/fa'
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md'

const SectionTokenCreation = ({ onTokenInfoChange }) => {
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [tokenDecimals, setTokenDecimals] = useState(0)
  const [tokenTotal, setTokenTotal] = useState(1)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [tokenImage, setTokenImage] = useState(null)

  const tokenInfo = {
    name: tokenName,
    symbol: tokenSymbol,
    description: tokenDescription,
    image: uploadedImage,
    decimals: tokenDecimals,
    total: tokenTotal,
    guildId: localStorage.getItem('selectedServer'),
  }

  useEffect(() => {
    onTokenInfoChange(tokenInfo)
  }, [
    tokenName,
    tokenSymbol,
    tokenDescription,
    uploadedImage,
    tokenDecimals,
    tokenTotal,
  ])

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
      if (!allowedTypes.includes(file.type)) {
        toast.error('Please select a valid image file (JPG, JPEG, PNG, GIF).')
        setUploadedImage(null)
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        setUploadedImage(e.target.result)
        setTokenImage(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDeleteImage = () => {
    setUploadedImage(null)
    setTokenImage(null)
  }

  // Token Decimals
  const incrementTokenDecimals = () => setTokenDecimals(tokenDecimals + 1)
  const decrementTokenDecimals = () => {
    if (tokenDecimals <= 0) {
      setTokenDecimals(0)
    } else {
      setTokenDecimals(tokenDecimals - 1)
    }
  }

  // Token Supply
  const incrementTokenSupply = () => setTokenTotal(tokenTotal + 1)
  const decrementTokenSupply = () => {
    if (tokenTotal <= 1) {
      setTokenTotal(1)
    } else {
      setTokenTotal(tokenTotal - 1)
    }
  }

  return (
    <>
      <Toaster
        position="top-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
      <Card className="w-full max-w-[900px] h-full bg-basic p-5 text-white shadow-none">
        <CardHeader className="bg-third p-2 flex items-center justify-center text-white font-semibold uppercase tracking-wide">
          Create a new token
        </CardHeader>
        <CardBody className="flex w-full h-full">
          <div className="w-full flex flex-col lg:flex-row gap-5 ">
            {/* section image upload */}
            <div className="lg:w-56 h-full">
              <div className="flex flex-col gap-y-1 h-full items-center justify-center">
                <span className="text-sm font-semibold uppercase w-full text-center">
                  Image
                </span>
                <Button
                  color="deep-purple"
                  onClick={() =>
                    document.getElementById('imageUploadInput').click()
                  }
                  className="bg-second w-full min-w-32 max-w-40 h-full min-h-32 max-h-40 rounded-lg flex items-center justify-center hover:bg-second/85 m-0 p-1"
                >
                  {uploadedImage ? (
                    <div className="w-full h-full flex items-center justify-center relative group">
                      <img
                        src={uploadedImage}
                        alt="Token Image"
                        className="w-full max-w-40 h-full max-h-40 object-contain rounded-lg"
                      />
                      <span className="w-full h-full absolute text-white hidden group-hover:flex z-30 bg-black/60 p-2 items-center justify-center rounded-lg">
                        Choose image
                      </span>
                      <Button
                        onClick={handleDeleteImage}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 z-50"
                      >
                        <FaTimes />
                      </Button>
                    </div>
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <FaCloudUploadAlt size={50} className="text-third" />
                    </div>
                  )}
                </Button>
                <input
                  id="imageUploadInput"
                  type="file"
                  accept=".png, .jpeg, .jpg, .gif"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
                <span className="text-[10px] text-mainHover font-semibold">
                  JPG, JPEG, PNG, GIF*
                </span>
              </div>
            </div>
            {/* section token info */}
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col lg:flex-row gap-2 w-full">
                {/* Token Name */}
                <div className="w-full">
                  <div className="w-full flex flex-col justify-end">
                    <span className="flex w-full items-end text-sm font-semibold uppercase">
                      Name*
                    </span>
                    <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                      <Input
                        placeholder="Ex: Bonk, King, Dust, etc."
                        color="deep-purple"
                        variant="static"
                        className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                        size="lg"
                        containerProps={{
                          className: 'bg-second ',
                        }}
                        labelProps={{
                          className:
                            'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                          style: {
                            color: 'white',
                          },
                        }}
                        value={tokenName}
                        onChange={(e) => setTokenName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* Token Symbol */}
                <div className="w-full lg:w-52">
                  <div className="w-full flex flex-col justify-end">
                    <span className="flex w-full items-end text-sm font-semibold uppercase">
                      symbol*
                    </span>
                    <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                      <Input
                        placeholder="Ex: BNK, KNG, DST, etc."
                        color="deep-purple"
                        variant="static"
                        className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                        size="lg"
                        containerProps={{
                          className: 'bg-second ',
                        }}
                        labelProps={{
                          className:
                            'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                          style: {
                            color: 'white',
                          },
                        }}
                        value={tokenSymbol.toUpperCase()}
                        onChange={(e) =>
                          setTokenSymbol(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Token Description */}
              <div className="w-full">
                <div className="w-full flex flex-col justify-end">
                  <span className="flex w-full items-end text-sm font-semibold uppercase">
                    Description
                  </span>
                  <div className="w-full flex flex-col justify-end bg-second rounded-lg px-1 border border-third">
                    <Input
                      placeholder="Write the description you want for your SPL Token"
                      color="deep-purple"
                      variant="static"
                      className=" focus:pl-5 w-full text-white p-2  font-bold text-lg first-of-type:pl-4 border-none"
                      size="lg"
                      containerProps={{
                        className: 'bg-second ',
                      }}
                      labelProps={{
                        className:
                          'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
                        style: {
                          color: 'white',
                        },
                      }}
                      value={tokenDescription}
                      onChange={(e) => setTokenDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="w-full flex flex-col lg:flex-row justify-between gap-2">
                  {/* Token Supply */}
                  <div className=" w-full flex items-end justify-end">
                    <div className="w-full flex flex-col items-start justify-end p-px rounded-lg">
                      <span className="flex w-full items-end text-sm font-semibold uppercase">
                        Supply*
                      </span>
                      <div
                        className={`flex items-end gap-x-0.5 transition duration-300 bg-second rounded-lg h-full w-full p-1 selection:bg-second`}
                      >
                        <input
                          type="number"
                          min={0}
                          value={tokenTotal}
                          onChange={(e) => {
                            setTokenTotal(Number.parseInt(e.target.value) || 1)
                          }}
                          className="w-full bg-transparent focus-within:outline-none px-4 py-1 m-1 "
                        />
                        <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
                          <Button
                            onClick={incrementTokenSupply}
                            className="bg-mainHover p-1 h-1/2 rounded-b"
                          >
                            <MdOutlineAdd />
                          </Button>
                          <Button
                            onClick={decrementTokenSupply}
                            className="bg-mainHover p-1 h-1/2 rounded-t"
                          >
                            <MdOutlineRemove />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Token Decimals */}
                  <div className=" w-full flex items-end justify-end">
                    <div className="w-full flex flex-col items-start justify-end p-px rounded-lg">
                      <span className="flex w-full items-end text-sm font-semibold uppercase">
                        Decimals*
                      </span>
                      <div
                        className={`flex items-end gap-x-0.5 transition duration-300 bg-second rounded-lg h-full w-full p-1 selection:bg-second`}
                      >
                        <input
                          type="number"
                          min={0}
                          value={tokenDecimals}
                          onChange={(e) => {
                            setTokenDecimals(
                              Number.parseInt(e.target.value) || 0
                            )
                          }}
                          className="w-full bg-transparent focus-within:outline-none px-4 py-1 m-1 "
                        />
                        <div className="h-full flex flex-col gap-y-0.5 items-center justify-center">
                          <Button
                            onClick={incrementTokenDecimals}
                            className="bg-mainHover p-1 h-1/2 rounded-b"
                          >
                            <MdOutlineAdd />
                          </Button>
                          <Button
                            onClick={decrementTokenDecimals}
                            className="bg-mainHover p-1 h-1/2 rounded-t"
                          >
                            <MdOutlineRemove />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default SectionTokenCreation
