export const getWallets = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/wallets?guildId=${localStorage.getItem('guildId')}`
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error('Error fetching wallets')
  }
  return data
}
