import { Button, Dialog, Spinner } from '@material-tailwind/react'
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import tek from '../../img/AvatarTektools.png'
import NftSetup from './_components/NftSetup'
import RoleSetup from './_components/RoleSetup'
import TokenSetup from './_components/TokenSetup'
import ImgNFT from './_components/imgNFT'
import ImgToken from './_components/imgToken'

const CreateHolderVerification = forwardRef(({ updateLoadCollection }, ref) => {
  useImperativeHandle(ref, () => ({
    triggerDefinitionStep2: () => {
      if (activeStep === 0) {
        handleNext()
      } else if (activeStep === 1) {
        handleDefinitionStep2()
      } else if (activeStep === 2) {
        handleRolesStep3()
      }
    },
  }))

  const navigate = useNavigate()
  const [selectTypeHolder, setSelectTypeHolder] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isLastStep, setIsLastStep] = useState(false)
  const [isFirstStep, setIsFirstStep] = useState(false)
  const [roleToken, setRoleToken] = useState(null)
  const [roleNft, setRoleNft] = useState(null)
  const [quantityToken, setQuantityToken] = useState(1)
  const [creatorAddress, setCreatorAddress] = useState(null)
  const [tokenAddress, setTokenAddress] = useState(null)
  const [collectionAddress, setCollectionAddress] = useState(null)
  const [collectionName, setCollectionName] = useState(null)
  const [rolePerQty, setRolePerQty] = useState(null)
  const [hashlist, setHashlist] = useState(null)
  const [hashlistFile, setHashlistFile] = useState(null)
  const [skipRole, setSkipRole] = useState(null)
  const [loading, setLoading] = useState(false)
  const [doneSync, setDoneSync] = useState(false)
  const [openDoneSync, setOpenDoneSync] = useState(false)
  const [syncData, setSyncData] = useState(null)
  const [roleAttribute, setRoleAttribute] = useState(null)
  const [isListings, setIsListings] = useState(false)
  const [isSales, setIsSales] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleRoleTokenSelect = (selectedRole) => {
    setRoleToken(selectedRole)
  }
  const handleRoleNftSelect = (selectedRole) => {
    setRoleNft(selectedRole)
  }
  const handleQuantityTokenSelect = (quantity) => {
    setQuantityToken(quantity)
  }
  const handleTokenAddress = (tokenAddress) => {
    setTokenAddress(tokenAddress)
  }
  const handleCreatorAddress = (creatorAddress) => {
    setCreatorAddress(creatorAddress)
  }
  const handleCollectionName = (collectionName) => {
    setCollectionName(collectionName)
  }
  const handleCollectionAddress = (collectionAddress) => {
    setCollectionAddress(collectionAddress)
  }
  const handleHashlist = (hashlist) => {
    setHashlist(hashlist)
  }
  const handleSkipRole = (skipRole) => {
    setSkipRole(skipRole)
  }
  const handleHashlistFile = (hashlistFile) => {
    setHashlistFile(hashlistFile)
  }
  const handleRolesQuantity = (rolesQuantity) => {
    setRolePerQty(rolesQuantity)
  }
  const handleRolesAttribute = (rolesAttribute) => {
    setRoleAttribute(rolesAttribute)
  }
  const handleIsListings = (isListings) => {
    setIsListings(isListings)
  }
  const handleIsSales = (isSales) => {
    setIsSales(isSales)
  }
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1)
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1)

  const handleDefinitionStep2 = async () => {
    setLoading(true)
    const errors = []

    if (!collectionName) {
      errors.push('Collection name is required.')
    }
    if (!(creatorAddress || collectionAddress || tokenAddress)) {
      errors.push('A valid address is required.')
    }
    if (!(selectTypeHolder ? roleToken?.value : roleNft?.value)) {
      errors.push('Please select a role.')
    }

    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }

    const infoDefinition = {
      guildId: localStorage.getItem('guildId'),
      isToken: selectTypeHolder,
      minimumQuantity: selectTypeHolder ? quantityToken : 1,
      collectionName: collectionName,
      creatorAddress: selectTypeHolder ? '' : creatorAddress,
      tokenAddress: tokenAddress ? tokenAddress : '',
      collectionMint: selectTypeHolder ? '' : collectionAddress,
      hashlist: hashlist ? [hashlistFile] : [],
      holderRole: selectTypeHolder ? roleToken.value : roleNft.value,
      skipRoleManagement: skipRole,
    }
    console.log('api call...', infoDefinition)
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/definition`
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoDefinition),
      })
      if (!response.ok) {
        if (response.status === 409) {
          throw new Error('A collection with this address already exists.')
        }
        if (response.status === 500) {
          throw new Error(
            'You are not authorized to create a collection in this server.'
          )
        }
        if (response.status === 400) {
          throw new Error(
            selectTypeHolder
              ? 'Confirm the token address used'
              : 'Confirm the collection address used'
          )
        }
        throw new Error('Ops... something gone wrong.')
      }
      const data = await response.json()
      console.log('Response Data:', data)
      const responseData = data
      setSyncData(data.length === 0 ? null : data)
      setLoading(false)
      setOpenDoneSync(true)
      toast.success('Data sync successfully!')
      const event = new Event('updateLoadHolderVerification')
      window.dispatchEvent(event)
    } catch (error) {
      console.error('Fetch error:', error)
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleRolesStep3 = async () => {
    setLoading(true)
    if (syncData) {
      const transformedRoleAttribute = roleAttribute.map((attr) => ({
        roleId: attr.RoleId,
        roleName: attr.RoleName,
        attributeName: attr.AttributeName,
        attributeValue: attr.AttributeValue,
      }))

      const transformedRolePerQty = rolePerQty.map((qty) => ({
        roleId: qty.RoleId,
        roleName: qty.RoleName,
        numberOfNfts: qty.NumberOfNfts,
      }))

      const infoRoles = {
        guildId: localStorage.getItem('guildId'),
        collectionId: syncData._id,
        // holderVerificationChannelId: holderVerificationChannelId,
        rolePerAttribute: transformedRoleAttribute,
        rolePerQty: transformedRolePerQty,
        listing: isListings,
        sales: isSales,
      }
      console.log('api call...', infoRoles)
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/roles`
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(infoRoles),
        })
        console.log('response', response)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const text = await response.text()
        const data = text ? JSON.parse(text) : {}
        toast.success('List of configurations sent successfully!')
        setIsLastStep(true)
        successConfig()
      } catch (error) {
        console.error('Errr send configurations:', error)
        setLoading(false)
        toast.error(`Opss, something gone wrong: ${error.message}`)
      }
    } else {
      toast.error('Ops...')
      setLoading(false)
      return
    }
  }

  const nextSync = () => {
    handleNext()
    setDoneSync(true)
  }

  const [channels, setChannels] = useState([])
  const [holderVerificationChannelId, setHolderVerificationChannelId] =
    useState(null)
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/channels/${localStorage.getItem('selectedServer')}`
        )
        const data = await response.json()
        if (Array.isArray(data)) {
          setChannels(data)
          const channel =
            data.find((channel) =>
              channel.name.toLowerCase().includes('holder')
            ) ||
            data.find((channel) =>
              channel.name.toLowerCase().includes('general')
            )

          if (channel) {
            setHolderVerificationChannelId(channel.id)
          }
        } else {
          console.error('Error: Channels data is not an array')
        }
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    }
    fetchChannels()
  }, [])

  const successConfig = () => {
    setSuccess(true)
  }

  const handleCongratulation = () => {
    window.location.reload()
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))
  }

  return (
    <>
      <section className="flex flex-col relative">
        {loading && (
          <div className="fixed w-full h-full top-0 left-0 bg-dark/60 z-50 flex items-center justify-center">
            <Spinner className="w-12 h-12 text-main/50" />
          </div>
        )}
        <Dialog
          className="flex flex-col text-white bg-basic rounded-lg"
          color="dark"
          size="xs"
          open={openDoneSync}
          handler={() => setOpenDoneSync(false)}
        >
          <Toaster
            position="bottom-right"
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            toastOptions={{
              className: 'text-base',
              style: {
                padding: '20px',
                color: '#fff',
                backgroundColor: '#1E1E1E',
              },
            }}
            zIndex={1000}
          />
          <div className="p-4 border border-third rounded-lg shadow-shadow">
            <div className="flex flex-col gap-y-2">
              <span className="text-3xl font-bold">
                Fetch data successfully!
              </span>
              <span className="text-xl">
                Now you can configure your collection
              </span>
            </div>
            <div className="flex justify-end items-end gap-2 mt-4">
              <Button
                className="bg-main"
                onClick={() => {
                  nextSync()
                  setOpenDoneSync(false)
                }}
                disabled={isLastStep}
              >
                Next
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          className="flex flex-col text-white bg-basic rounded-lg"
          color="dark"
          size="xs"
          open={success}
          onClose={() => setSuccess(false)}
        >
          <Toaster
            position="bottom-right"
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            toastOptions={{
              className: 'text-base',
              style: {
                padding: '20px',
                color: '#fff',
                backgroundColor: '#1E1E1E',
              },
            }}
            zIndex={1000}
          />
          <div className="p-4 border border-third rounded-lg shadow-shadow">
            <div className="flex flex-col gap-y-2">
              <span className="text-3xl font-bold">
                Configuration successfully!
              </span>
              <span className="text-xl">
                Select channel to send instructions
              </span>
            </div>
          </div>
          <Button
            className="bg-main"
            onClick={() => {
              handleCongratulation()
            }}
          >
            Done
          </Button>
        </Dialog>
        <div className="container mx-auto flex flex-col gap-y-5 py-5 h-full ">
          {/* Content */}
          <div className="flex">
            {/* Set Type Holder: NFT or TOKEN */}
            {activeStep === 0 && (
              <div className="flex flex-col w-full h-full gap-y-3 ">
                <span className="text-lg font-bold">
                  Select the wallet type to configure your collection
                </span>
                <div className="flex w-full flex-row justify-center items-center h-full ">
                  <div className=" bg-dark p-2 rounded-lg flex flex-row -space-x-16 lg:-space-x-32 ">
                    <div
                      className={`${selectTypeHolder ? 'grayscale shadow-inner' : 'scale-[1.02]'} transition-all duration-700 flex `}
                      onClick={() => setSelectTypeHolder(false)}
                    >
                      <ImgNFT />
                      <div
                        className={`${!selectTypeHolder ? 'w-[65%] ' : 'w-[40%]'} text-start absolute bottom-16 left-0 text-xl lg:text-5xl bg-dark font-bold p-2 color-change transition-all duration-300 flex items-center gap-x-2 rounded-tr-3xl`}
                      >
                        <img
                          src={tek}
                          alt="Tektools"
                          className={`${!selectTypeHolder ? 'w-8 lg:w-12 opacity-100' : 'opacity-0 hidden'} transition-all duration-700 ease-in-out h-8 lg:h-12`}
                        />
                        NFT
                      </div>
                    </div>
                    {/* --------------------------------------------------------------------------------------------------- */}
                    <div
                      className={`${selectTypeHolder ? 'scale-[1.02]' : 'grayscale shadow-inner '} transition-all duration-300 flex `}
                      onClick={() => setSelectTypeHolder(true)}
                    >
                      <ImgToken />
                      <div
                        className={`${selectTypeHolder ? 'w-[70%] lg:w-[80%]' : 'w-[50%] lg:w-[60%]'} text-end absolute top-16 right-0 text-xl lg:text-5xl bg-dark font-bold p-2 color-change transition-all duration-500 flex items-center gap-x-2`}
                      >
                        TOKEN
                        <img
                          src={tek}
                          alt="Tektools"
                          className={`${selectTypeHolder ? 'w-8 lg:w-12 opacity-100' : 'opacity-0 hidden'} transition-all duration-700 ease-in-out h-8 lg:h-12`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Define attributes */}
            {activeStep === 1 && (
              <div className="flex flex-col w-full h-full gap-4 items-center justify-center">
                {selectTypeHolder ? (
                  <TokenSetup
                    onRoleSelect={handleRoleTokenSelect}
                    onQuantitySelect={handleQuantityTokenSelect}
                    onTokenAddress={handleTokenAddress}
                    onCollectionName={handleCollectionName}
                    onSkipRole={handleSkipRole}
                  />
                ) : (
                  <NftSetup
                    onRoleSelect={handleRoleNftSelect}
                    onQuantitySelect={handleQuantityTokenSelect}
                    onCreatorAddress={handleCreatorAddress}
                    onCollectionName={handleCollectionName}
                    onCollectionAddress={handleCollectionAddress}
                    onHashlist={handleHashlist}
                    onSkipRole={handleSkipRole}
                    onHashlistFile={handleHashlistFile}
                  />
                )}
              </div>
            )}
            {/* Define notifications */}
            {activeStep === 2 && (
              <div className="flex flex-col w-full h-full gap-y-3 items-center justify-center">
                <RoleSetup
                  syncData={syncData}
                  onRolesQuantity={handleRolesQuantity}
                  onRolesAttribute={handleRolesAttribute}
                  onListings={handleIsListings}
                  onSales={handleIsSales}
                />
              </div>
            )}
          </div>
          {/* botão  */}
          <hr className="w-full border-third" />
          <div className="bottom-5 left-5 flex py-20 md:py-5 justify-end gap-x-2">
            {activeStep !== 0 && (
              <Button className="bg-dark" onClick={() => handlePrev()}>
                Prev
              </Button>
            )}
            <Button
              className="bg-main "
              onClick={() => {
                if (activeStep === 0) {
                  handleNext()
                } else if (activeStep === 1) {
                  handleDefinitionStep2()
                } else if (activeStep === 2) {
                  handleRolesStep3()
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </section>
      <Toaster
        position="top-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
    </>
  )
})
export default CreateHolderVerification
