import { Spinner } from "@material-tailwind/react"

export const Loading = () => {
  return (
    <div className="fixed w-full h-full top-0 left-0 bg-dark/60 z-50 flex items-center justify-center">
      <Spinner className="w-12 h-12 text-main/50" />
    </div>
  )
}

