import { Button, Input } from '@material-tailwind/react'
import React, { useState, useEffect, useRef } from 'react'
import { getStaking } from '../../utils/stakingUtils'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { InputDefault, InputNumber } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'
import { getRoles } from '../../utils/rolesUtils'
import { getChannel } from '../../utils/channelUtils'
import { getCollection } from '../../utils/collectionUtils'
import TraitRewards from './_components/TraitRewards'

const EditStakingSetup = () => {

  // load roles
  const [roles, setRoles] = useState([])
  const loadRoles = async () => {
    const data = await getRoles()
    setRoles(data)
  }
  useEffect(() => {
    if (roles.length === 0) {
      loadRoles()
    }
  }, [])

  const dropdownRoles = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  // load channels
  const [channels, setChannels] = useState([])

  const loadChannel = async () => {
    const guildId = localStorage.getItem('guildId')
    const data = await getChannel(guildId)
    setChannels(data)
  }

  useEffect(() => {
    loadChannel()
  }, [])

  const dropdownChannels = channels?.map((channel) => ({
    value: channel.id,
    label: channel.name,
  }))


  const { id } = useParams()
  const navigate = useNavigate()

  const [listStaking, setListStaking] = useState([])

  const loadStaking = async () => {
    const data = await getStaking()
    setListStaking(data)
  }

  useEffect(() => {
    if (listStaking.length === 0) {
      loadStaking()
    }
  }, [])

  const dataStaking = listStaking
    ?.filter((staking) => staking._id === id)
    .map((staking) => {
      return {
        id: staking._id,
        GuildId: staking.GuildId,
        RoleAbleToStake: staking.RoleAbleToStake,
        PointSystemName: staking.PointSystemName,
        StakedCollections: staking.StakedCollections,
        StakingChannelId: staking.StakingChannelId,
        TokenAddress: staking.StakedTokens[0]?.TokenAddress,
        TokenName: staking.StakedTokens[0]?.TokenName,
        TokenImage: staking.StakedTokens[0]?.TokenImage,
        TraitBased: staking.TraitBased,
        UsesPointSystem: staking.UsesPointSystem,
        TotalRewardPerDay: staking.TotalRewardPerDay,
        WithdrawMinimumBalance: staking.WithdrawMinimumBalance,
        WithdrawMinimumDays: staking.WithdrawMinimumDays,
        WithdrawMinimumDays: staking.WithdrawMinimumDays,
        traitRewardList: staking.StakedCollections
          ? staking.StakedCollections.flatMap(
            (collection) => collection.TraitRewards || []
          )
          : null,
      }
    })

  //load collections
  const [collections, setCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  const loadCollections = async () => {
    const data = await getCollection()
    setCollections(data)
  }
  useEffect(() => {
    loadCollections()
  }, [])

  const collectionOptions = collections?.flatMap(
    (collection) =>
      collection.CollectionNotifications?.filter(
        (notification) => notification.IsEnabled && !notification.IsToken
      ).map((notification) => ({
        value: [
          {
            CollectionAddress: notification.CollectionMint,
            VerifiedAddress: notification.VerifiedAddress,
            _id: collection._id,
          },
        ],
        label: notification.DisplayName,
      })) || []
  )

  const traitRewards = {
    CollectionAddress: dataStaking[0]?.StakedCollections[0]?.CollectionMint,
  }

  console.log('collectionOptions', collectionOptions)
  console.log('dataStaking', dataStaking[0])
 
  return (
    <>
      {/* Body */}
      <section className="flex w-full justify-center text-white py-2 sm:py-5 relative h-full">
        <div className="container mx-auto w-full max-w-[1000px]">
          {/* header */}
          <div className="flex justify-between items-center pb-10">
            <h1 className="text-2xl font-bold h-full flex items-end">
              {
                dataStaking[0]
                  ? `Edit Staking`
                  : 'Loading...'}
            </h1>
            <Button
              color="deep-purple"
              className="bg-main hover:bg-mainHover w-20 h-full max-h-[52px] flex text-center items-center justify-center"
              onClick={() => navigate(-1)}
            >
              <BiLeftArrowAlt size={18} />
            </Button>
          </div>
          {/* body */}
          <div className='flex flex-col gap-y-10'>
            <div className='flex w-full justify-center items-center gap-x-5'>
              <img
                className='w-40 h-40 object-auto bg-black rounded-full flex items-center justify-center'
                src={dataStaking[0]?.TokenImage}
                alt={dataStaking[0]?.TokenName}
              />
              <div className='flex flex-col gap-y-5 w-full'>
                <InputDefault
                  label='Token name'
                  value={dataStaking[0]?.TokenName}
                  readOnly={true}
                />
                <InputDefault
                  label='Token address'
                  value={dataStaking[0]?.TokenAddress}
                  readOnly={true}
                />
              </div>
            </div>
            <div className='flex gap-x-5'>
              <InputNumber
                label='Daily reward amount'
                value={dataStaking[0]?.TotalRewardPerDay}
                maxSize={true}
                readOnly={true}
              />
              <InputNumber
                label='Minimum withdrawal balance'
                value={dataStaking[0]?.WithdrawMinimumBalance}
                maxSize={true}
                readOnly={true}
              />
              <InputNumber
                label='Minimum withdrawal days'
                value={dataStaking[0]?.WithdrawMinimumDays}
                maxSize={true}
                readOnly={true}
              />
            </div>
          </div>
          <hr className="w-full border-third my-10" />
          <div className='flex flex-col gap-y-5'>
            <div className='flex gap-x-5'>
              <SelectDefault 
                multiple={true}
                label='Roles able to stake' 
                options={dropdownRoles} 
                value={dropdownRoles.filter(role => 
                  dataStaking[0]?.RoleAbleToStake.includes(role.value)
                )}
                isMulti={true}
              />
              <SelectDefault 
                label='Channel for staking' 
                options={dropdownChannels} 
                value={dropdownChannels.filter(channel => channel.value === dataStaking[0]?.StakingChannelId)}
              />
            </div>
            <SelectDefault 
              label='Collections to stake' 
              isMulti={true}
              options={collectionOptions}
              value={collectionOptions.filter(collectionOption => 
                dataStaking[0]?.StakedCollections.some(stakedCollection => 
                  stakedCollection.CollectionId === collectionOption.value[0].VerifiedAddress || 
                  stakedCollection.CollectionId === collectionOption.value[0].CollectionAddress
                )
              )}
            />
            <TraitRewards onCollections={traitRewards} />
          </div>
        </div>
      </section>
    </>
  )
}

export default EditStakingSetup
