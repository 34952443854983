import nft from '../../../img/NFT.png'

export default function ImgNFT() {
  return (
    <>
      <img
        src={nft}
        alt="NFT"
        className="rounded-l-lg object-cover h-[300px] lg:h-[500px]"
      />
    </>
  )
}
