import React, { useState, useEffect } from 'react'
import { Button } from '@material-tailwind/react'
import { Toaster, toast } from 'react-hot-toast'
import { getChannel } from '../../utils/channelUtils'
import { getCollection } from '../../utils/collectionUtils'
import { getRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'
import ConditionsRewards from './_components/ConditionsRewards'
import { SpecificRewards } from './_components/SpecificRewards'
import SwitchSpecificReward from './_components/SwitchSpecificReward'
import TokenOption from './_components/TokenOption'
import { Loading } from '../components/ui/loading'

export default function CreateStakingSetup() {
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [isSpecificReward, setIsSpecificReward] = useState(false)
  const [rewardsPerDays, setRewardsPerDays] = useState(0)
  const [minimumWithdrawBalance, setMinimumWithdrawBalance] = useState(0)
  const [minimumWithdrawDays, setMinimumWithdrawDays] = useState(0)
  const [stakedCollections, setStakedCollections] = useState([])
  const [stakedTokens, setStakedTokens] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleRewardsPerDays = (value) => {
    setRewardsPerDays(value)
  }
  const handleMinimumWithdrawBalance = (value) => {
    setMinimumWithdrawBalance(value)
  }
  const handleMinimumWithdrawDays = (value) => {
    setMinimumWithdrawDays(value)
  }

  const handleStakedTokensChange = (value) => {
    setStakedTokens(value)
  }

  // load roles
  const [roles, setRoles] = useState([])
  const loadRoles = async () => {
    const guildId = localStorage.getItem('guildId')
    const data = await getRoles(guildId)
    setRoles(data)
  }

  useEffect(() => {
    loadRoles()
  }, [])

  const dropdownRoles = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  }))

  // load channels
  const [channels, setChannels] = useState([])

  const loadChannel = async () => {
    const guildId = localStorage.getItem('guildId')
    const data = await getChannel(guildId)
    setChannels(data)
  }

  useEffect(() => {
    loadChannel()
  }, [])

  const dropdownChannels = channels?.map((channel) => ({
    value: channel.id,
    label: channel.name,
  }))

  //load collections
  const [collections, setCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  const loadCollections = async () => {
    const data = await getCollection()
    setCollections(data)
  }
  useEffect(() => {
    loadCollections()
  }, [])

  const collectionOptions = collections?.flatMap(
    (collection) =>
      collection.CollectionNotifications?.filter(
        (notification) => notification.IsEnabled && !notification.IsToken
      ).map((notification) => ({
        value: [
          {
            CollectionAddress: notification.CollectionMint,
            VerifiedAddress: notification.VerifiedAddress,
          },
        ],
        label: notification.DisplayName,
      })) || []
  )

  const handleRoleChange = (value) => {
    setSelectedRole(value)
  }

  const handleChannelChange = (value) => {
    setSelectedChannel(value)
  }

  const handleCollectionChange = (value) => {
    setSelectedCollection(value)
  }

  const handleCreateStakingSetup = async () => {
    setLoading(true)
    const errors = []
    if (!selectedRole) {
      errors.push('Please select a role.')
    }
    if (!selectedChannel) {
      errors.push('Please select a channel.')
    }
    if (!selectedCollection) {
      errors.push('Please select a collection.')
    }
    if (!stakedTokens) {
      errors.push('Please select a staked token.')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }

    const infoCreateStaking = {
      guildId: localStorage.getItem('guildId'),
      eligibleRole: [selectedRole.value],
      stakedTokens: [
        {
          tokenName: stakedTokens.label,
          tokenAddress: stakedTokens.value,
          tokenImage: stakedTokens.image,
        },
      ],
      stakingChannelId: selectedChannel.value,
      rewardDetails: {
        dailyRewardAmount: rewardsPerDays,
        minimumWithdrawalBalance: minimumWithdrawBalance,
        minimumWithdrawalDays: minimumWithdrawDays,
      },
      stakedCollections,
    }
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/staking`
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoCreateStaking),
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const text = await response.text()
      const data = text ? JSON.parse(text) : {}
      toast.success('Staking setup created successfully!')
      setLoading(false)
    } catch (error) {
      console.error('Error creating staking setup:', error)
      toast.error(`Opss, something gone wrong: ${error.message}`)
      setLoading(false)
    }
  }

  return (
    <>
      {loading === true && <Loading />}
      <section className="flex flex-col relative">
        <div className="container mx-auto flex flex-col gap-y-5 py-5 h-full ">
          {/* section 1 */}
          <div className="flex gap-x-5">
            {/* Select Role */}
            <SelectDefault
              label="Select a Role"
              placeholder="Select Role"
              options={dropdownRoles}
              onChange={handleRoleChange}
            />

            {/* Select Channel */}
            <SelectDefault
              label="Select a Channel"
              placeholder="Select Channel"
              options={dropdownChannels}
              onChange={handleChannelChange}
            />
          </div>
          {/* Select Collection */}
          <SelectDefault
            label="Select a Collection"
            placeholder="Select Collection"
            isMulti={true}
            options={collectionOptions}
            onChange={handleCollectionChange}
          />
          <hr className="w-full border-third my-10" />
          {/* section 2 */}
          <div className="flex flex-col gap-y-8">
            <TokenOption onStakedTokensChange={handleStakedTokensChange} />
            <ConditionsRewards
              onRewardsPerDaysChange={handleRewardsPerDays}
              onMinimumWithdrawBalanceChange={handleMinimumWithdrawBalance}
              onMinimumWithdrawDaysChange={handleMinimumWithdrawDays}
            />
            <hr className="w-full border-third my-10" />
          </div>
          {/* section 3 */}
          {selectedCollection?.length > 0 && (
            <div className="flex flex-col gap-3 w-full">
              {/* Switch Specific Reward */}
              <SwitchSpecificReward
                isChecked={isSpecificReward}
                setIsChecked={() => setIsSpecificReward(!isSpecificReward)}
              />
              <div className={`w-full h-full transition-all duration-300 my-10 ${isSpecificReward ? 'flex' : 'hidden'}`}>
                <SpecificRewards
                  collectionsRewards={selectedCollection}
                  setStakedCollections={setStakedCollections}
                />
              </div>

            </div>
          )}
          {/* section 4 - button */}
          <div className="flex justify-end my-5">
            <Button
              color="deep-purple"
              className="bg-main"
              size="lg"
              onClick={handleCreateStakingSetup}
            >
              Create
            </Button>
          </div>
        </div>

      </section>
      <Toaster
        position="top-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
    </>
  )
}
