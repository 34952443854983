import { Button, Dialog } from '@material-tailwind/react'
import React, { useState } from 'react'
import CreateVotingSystem from './CreateVotingSystem'
import LoadVotingSystem from './LoadVotingSystem'

const VotingSystem = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <section className="container mx-auto text-white">
        <div className="flex flex-col overflow-y-auto gap-y-5">
          {/* titulo */}
          <div className="flex flex-col gap-y-3">
            <h1 className="w-full flex justify-between text-4xl font-bold">
              Voting System
              <Button onClick={() => setOpen(true)} color="purple">
                Create Poll
              </Button>
            </h1>
            <h2 className="text-2xl font-medium">
              Position that the user must have to be able to vote.
            </h2>
            <div className="flex justify-end"></div>
          </div>
          <hr className="h-px mb-5 bg-gray border-0" />
          <div className="w-full flex">
            <LoadVotingSystem />
          </div>
          <div className="flex items-center justify-center"></div>
        </div>
      </section>
      <Dialog
        className="flex flex-col text-white bg-bgAdmin overflow-y-scroll overflow-hidden"
        open={open}
        handler={() => setOpen(false)}
      >
        <CreateVotingSystem />
      </Dialog>
    </>
  )
}

export default VotingSystem
