import { Button, Card } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { BiTrash } from 'react-icons/bi'
import { getWallets } from '../../utils/walletUtils'

const LoadWallet = ({ walletCreated, onWalletCreated }) => {
  const [wallets, setWallets] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchWallets = async () => {
    const fetchedWallets = await getWallets()
    setWallets(fetchedWallets)
    onWalletCreated(fetchedWallets)
  }

  useEffect(() => {
    fetchWallets()
  }, [walletCreated])

  const handleDeleteWallet = async (wallet) => {
    if (!wallet) return
    setLoading(true)
    const loadingToastId = toast.loading('Deleting wallet...')
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/wallets/${wallet.info}?guildId=${localStorage.getItem('guildId')}`
    try {
      const response = await fetch(apiUrl, { method: 'DELETE' })
      if (!response.ok) throw new Error('Failed to delete wallet')
      toast.dismiss(loadingToastId)
      toast.success('Wallet deleted successfully!')
      fetchWallets()
    } catch (error) {
      toast.dismiss(loadingToastId)
      toast.error('Error deleting wallet!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Toaster
        position="bottom-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
      {wallets.length > 0 && (
        <section className="bg-basic p-5 rounded-lg flex flex-col gap-y-5">
          <span className="text-xl font-semibold pl-2">My wallets</span>
          {wallets.length > 0 &&
            wallets.map((wallet, index) => (
              <Card
                className="flex flex-row p-5 justify-between items-center bg-second"
                key={index}
              >
                <div>{wallet.walletName}</div>
                <div className="flex items-center gap-x-1">
                  <Button
                    disabled={loading}
                    onClick={() => handleDeleteWallet(wallet)}
                    className="flex items-center justify-center"
                    size="sm"
                    variant="text"
                    color="red"
                  >
                    <BiTrash size={20} />
                  </Button>
                </div>
              </Card>
            ))}
        </section>
      )}
    </>
  )
}

export default LoadWallet
