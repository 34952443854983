import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import TraitRewards from './TraitRewards'

export const SpecificRewards = ({
  collectionsRewards,
  setStakedCollections,
}) => {
  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState(null)
  const [traitsByCollection, setTraitsByCollection] = useState({})

  const handleSelectCollection = (index) => {
    setSelectedCollectionIndex(index)
  }

  const handleUpdateTraits = (collectionIndex, updatedTraits) => {
    setTraitsByCollection((prev) => ({
      ...prev,
      [collectionIndex]: updatedTraits,
    }))

    setStakedCollections((prev) => {
      const newStakedCollections = [...prev]
      const collectionReward = collectionsRewards[collectionIndex]
      newStakedCollections[collectionIndex] = {
        collectionName: collectionReward.label,
        collectionMint: collectionReward.value[0].CollectionAddress,
        collectionId: collectionReward.value[0].VerifiedAddress,
        traitRewards: updatedTraits,
      }
      return newStakedCollections
    })
  }

  return (
    <div className="flex flex-col w-full">
      {collectionsRewards?.length > 0 && (
        <div
          className={`flex flex-row w-full border-b-2 gap-1 overflow-x-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic mb-5 ${selectedCollectionIndex !== null ? 'border-main' : ''}`}
        >
          {collectionsRewards.map((collection, index) => (
            <Button
              key={index}
              className={`cursor-pointer p-3 rounded-none rounded-t border-none  ${selectedCollectionIndex === index ? 'bg-main' : 'shadow-none'}`}
              onClick={() => handleSelectCollection(index)}
            >
              {collection.label}
            </Button>
          ))}
        </div>
      )}
      {selectedCollectionIndex !== null ? (
        <div className="flex flex-col w-full">
          {collectionsRewards.map((collection, index) => (
            <div
              key={index}
              className={selectedCollectionIndex === index ? 'block' : 'hidden'}
            >
              <TraitRewards
                onCollections={{
                  CollectionAddress: collection.value[0].CollectionAddress,
                  VerifiedAddress: collection.value[0].VerifiedAddress,
                }}
                rewards={traitsByCollection[index] || []}
                onUpdateTraits={(updatedTraits) =>
                  handleUpdateTraits(index, updatedTraits)
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <span>
          You can create custom trait specific reward based on your NFT metadata
          to give its holder extra rewards, example: NFTs with trait Head, trait
          value Golden Necklace, get 10 rewards per day.
        </span>
      )}
    </div>
  )
}
