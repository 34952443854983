import React from 'react'
import Header from '../../components/Header/Header'
import alpha from '../../img/alpha2.svg'

const HeaderAdmin = () => {
  return (
    <div className="flex flow-row z-50 h-20 ">
      <div className='w-full h-12 m-5 rounded-full p-px border border-main shadow-main shadow-md items-center justify-center flex text-white hidden'> text info</div>
      <div className=' w-48 h-12 my-5 mr-5 rounded-full p-px border border-main shadow-main shadow-md hidden'>
        <div className='bg-second/20 w-full h-full rounded-full items-center justify-center flex text-white'>user menu</div>
      </div>
    </div>
  )
}
export default HeaderAdmin
