import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { BiTrash } from 'react-icons/bi'

export const useFetchImages = () => {
  const [imageGallery, setImageGallery] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchImages = async () => {
    try {
      const guildId = localStorage.getItem('selectedServer')
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/storage?guildId=${guildId}`
      )
      if (!response.ok) throw new Error('Falha ao buscar imagens')
      const data = await response.json()
      if (data && Array.isArray(data.keys)) {
        setImageGallery(data.keys)
      } else {
        throw new Error('Formato de dados inválido')
      }
    } catch (error) {
      console.error('Erro:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return { imageGallery, isLoading, error, fetchImages }
}

const handleDeleteImage = async (imageUrl, fetchImages) => {
  if (!imageUrl) return
  const fileName = encodeURIComponent(imageUrl)
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/storage?guildId=${localStorage.getItem('guildId')}&fileName=${fileName}`
  try {
    const response = await fetch(apiUrl, { method: 'DELETE' })
    if (!response.ok) throw new Error('Failed to delete image')
    toast.success('Image deleted successfully')
    fetchImages()
  } catch (error) {
    console.error('Error deleting image:', error)
  }
}

const DecentralizedGallery = ({ images, isLoading, error }) => {
  if (isLoading) return <div>Carregando...</div>
  if (error) return <div>Erro: {error}</div>

  return (
    <>
      <Toaster
        position="bottom-right"
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
      <hr className="w-full container mx-auto text-third " />
      <div className="flex flex-wrap justify-center gap-5 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        {images.length > 0 ? (
          images
            .filter((imageUrl) => !imageUrl.endsWith('.json'))
            .map((imageUrl, index) => (
              <div
                key={imageUrl}
                className="w-1/6 flex flex-wrap justify-center gap-5  rounded-md relative group"
              >
                <div className="w-full h-full">
                  <img
                    src={imageUrl}
                    alt={`Imagem ${index + 1}`}
                    className="rounded-md zoom-in-50 p-1 group-hover:p-0 w-full h-full transition-all duration-300"
                  />
                  <button className="absolute inset-0 items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 flex">
                    {imageUrl.split('/').pop()}
                  </button>
                  <button
                    onClick={() => handleDeleteImage(imageUrl)}
                    className="absolute top-2 right-2 bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 flex"
                  >
                    <BiTrash size={20} />
                  </button>
                </div>
              </div>
            ))
        ) : (
          <p>No images found</p>
        )}
      </div>
    </>
  )
}

export default DecentralizedGallery
