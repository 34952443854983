export const getChannel = async (guildId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/channels/${guildId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  )
  console.log(response)
  if (!response.ok) {
    throw new Error('Erro na resposta da API')
  }
  const data = await response.json()
  return data
}
