import { Button, Card } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { BiX } from 'react-icons/bi'
import coin from '../../img/coin.png'
import ListTraitsReveneuSharing from './_components/ListTraitsReveneuSharing'

const LoadRevenueSharing = ({ onListRevSharing }) => {
  const [listRevSharing, setListRevSharing] = useState([])

  useEffect(() => {
    const fetchRevenueSharing = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/revenue-sharing/list?guildId=${localStorage.getItem('selectedServer')}`
      const response = await fetch(apiUrl)
      const data = await response.json()
      setListRevSharing(data)
    }
    fetchRevenueSharing()
  }, [])

  useEffect(() => {
    onListRevSharing(listRevSharing[0])
  }, [listRevSharing, onListRevSharing])

  return (
    <>
      <section className="container mx-auto flex flex-wrap gap-5 overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pb-10 items-center justify-center">
        {listRevSharing.map((reward, index) => {
          const isExpired = new Date() > new Date(reward.ExpireDate)
          return (
            <Card
              key={index}
              className="w-96 h-72 bg-second flex flex-row group text-white"
            >
              {/* esquerda */}
              <div
                div
                className="w-full bg-third rounded-lg shadow-xl shadow-accentMain/10 pl-3 flex flex-col gap-y-3 py-3"
              >
                <div className="flex flex-row gap-x-2 items-end border-b-8 transition-all duration-300 border-second rounded-l-full">
                  <img
                    src={coin}
                    alt="FakeCoin"
                    className="w-12 h-12  -mb-0.5 group-hover:scale-110 group-hover:rotate-12 transition-all duration-300"
                  />
                  <span className="text-xl font-bold group-hover:pl-2 transition-all duration-300">
                    {reward.TokenName}
                  </span>
                </div>
                <div className="text-end pr-5">
                  Amount per NFT:{' '}
                  <span className="font-bold">{reward.AmountPerNft}</span>
                </div>
                <div className="pr-3">
                  <ListTraitsReveneuSharing listTraits={reward.TraitRewards} />
                </div>
                <div className="flex flex-row justify-between p-3">
                  {isExpired ? (
                    <Button
                      variant="gradient"
                      color="purple"
                      className="text-white px-4 py-2 transition-all duration-300 w-40 relative"
                    >
                      <span className="absolute inset-0 flex items-center justify-center transition-opacity duration-500 group-hover:opacity-0">
                        Expired Revenue
                      </span>
                      <span className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        Renew Revenue
                      </span>
                    </Button>
                  ) : (
                    <div className="flex flex-col text-center">
                      End in{' '}
                      <span className="font-bold">
                        {reward.ExpireDate.split('T')[0]}
                      </span>
                    </div>
                  )}

                  <div className="flex flex-col text-center pr-2">
                    Min Stake{' '}
                    <span className="font-bold">
                      {reward.MinimumStakedDays} days
                    </span>
                  </div>
                </div>
              </div>
              {/* direita */}
              <div className="w-2 transition-all duration-300 "></div>
            </Card>
          )
        })}
      </section>
    </>
  )
}

export default LoadRevenueSharing
