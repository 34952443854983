import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'

const SendInstructions = ({ onClose }) => {
  const animatedComponents = makeAnimated()
  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/channels/${localStorage.getItem('guildId')}`
        )
        const data = await response.json()
        if (Array.isArray(data)) {
          setChannels(data)
        } else {
          console.error('Error: Channels data is not an array')
        }
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    }
    fetchChannels()
  }, [])

  const dropdownChannels = Array.isArray(channels)
    ? channels.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }))
    : []

  const sendInstructions = () => {
    if (!selectedChannel) {
      toast.error('Please select a channel.')
      return
    }
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/channels/send-message/holder-verification`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          channelId: selectedChannel.value,
          guildId: localStorage.getItem('guildId'),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        toast.success('Instructions sent successfully!')
        setSelectedChannel(null)
        setTimeout(() => {
          if (onClose) {
            onClose()
          }
        }, 500) // Adiciona um pequeno atraso para garantir que o toast seja exibido
      })
      .catch((error) => {
        console.error('Error sending instructions:', error)
        toast.error('Error sending instructions.')
      })
  }

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  useEffect(() => {
    if (selectedChannel) {
      sendInstructions()
    }
  }, [selectedChannel])

  return (
    <>
      {/* Select Channel */}
      <div className="w-full  flex flex-col">
        <span className="text-sm">Select a Channel</span>
        <div className="w-full  flex flex-col bg-second rounded-lg border border-third">
          <Select
            isMulti={false}
            options={dropdownChannels}
            value={selectedChannel}
            onChange={handleChannelChange}
            placeholder="Select channel"
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
            styles={{
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#2C2F48',
                color: '#fff',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                height: 'auto',
                maxHeight: '100%',
              }),
              container: (provided, state) => ({
                ...provided,
                margin: 0,
                padding: 0,
                border: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#fff',
              }),
              control: (provided, state) => ({
                ...provided,
                maxHeight: '100%',
                maxWidth: '100%',
                backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                color: '#fff',
                borderRadius: 8,
                borderColor:
                  state.isFocused || state.isHovered ? '#9945ff' : '#2C2F48',
                boxShadow:
                  state.isFocused || state.isHovered
                    ? '0 0 0 0.5px #9945ff'
                    : 'none',
                paddingLeft: '1rem',
                '&:hover': {
                  borderColor: '#9945ff',
                  boxShadow: '0 0 0 0.5px #9945ff',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#9945ff'
                  : state.isFocused
                    ? '#9945ff'
                    : '#2C2F48',
                color: '#fff',
                cursor: 'pointer',
                padding: '0 1rem',
                margin: 0,
              }),
              multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#9945ff',
                color: '#fff',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: '#fff',
              }),
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SendInstructions
