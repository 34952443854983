import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Switch from '../../admin/components/ui/switch'

const AntiBotMembers = () => {
  const animatedComponents = makeAnimated()
  const [isChecked, setIsChecked] = useState(false)
  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/channels/${localStorage.getItem('selectedServer')}`
        )
        const data = await response.json()
        if (Array.isArray(data)) {
          setChannels(data)
        } else {
          console.error('Error: Channels data is not an array')
        }
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    }
    fetchChannels()
  }, [])

  const dropdownChannels = Array.isArray(channels)
    ? channels.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }))
    : []

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('selectedServer')}`
        )
        const data = await response.json()
        if (Array.isArray(data)) {
          setRoles(data)
        } else {
          console.error('Error: Roles data is not an array')
        }
      } catch (error) {
        console.error('Error fetching roles:', error)
      }
    }
    fetchRoles()
  }, [])

  const dropdownRoles = Array.isArray(roles)
    ? roles.map((role) => ({
        value: role.id,
        label: role.name,
      }))
    : []

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption)
  }

  //button
  const saveClick = () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/verification`

    if (!selectedChannel) {
      toast.error('Please select a channel!')
      return
    }

    if (!selectedRole) {
      toast.error('Please select a role!')
      return
    }

    const infoAntiBotMembers = {
      serverId: localStorage.getItem('selectedServer'),
      channelId: selectedChannel.value,
      roleId: selectedRole.value,
      captcha: isChecked,
    }

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(infoAntiBotMembers),
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedRole(null)
        setSelectedChannel(null)
        setIsChecked(false)
        toast.success('Message sent successfully!')
      })
      .catch((error) => {
        toast.error('Opss, something gone wrong.')
      })
  }
  return (
    <>
      <Toaster
        position="bottom-right"
        gutter={24}
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        toastOptions={{
          className: 'text-base',
          style: {
            padding: '20px',
            color: '#fff',
            backgroundColor: '#1E1E1E',
          },
        }}
      />
      <section className="container mx-auto text-white">
        <div className=" flex flex-col gap-y-8 ">
          <h1 className="font-semibold text-4xl">Anti bot members</h1>
          <h2 className="font-semibold text-2xl">User Verification</h2>
          <p className="font text-lg tracking-wide">
            In order to join Toolbox NFTs we need first to verify you are not a
            bot, please click the verify button to start the verification
          </p>
          <div className="flex lg:flex-row flex-col gap-5">
            {/* Select Channel */}
            <div className="w-full flex flex-col justify-end">
              <span className="text-sm">Select a Channel</span>
              <div className="w-full flex flex-col bg-second rounded-lg border border-third">
                <Select
                  isMulti={false}
                  options={dropdownChannels}
                  value={selectedChannel}
                  onChange={handleChannelChange}
                  placeholder="Select channel"
                  className="basic-multi-select"
                  classNamePrefix="select overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic"
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#2C2F48',
                      color: '#fff',
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#B420FF #2C2F48',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                      height: 'auto',
                      maxHeight: '100%',
                      color: '#fff',
                      alignItems: 'center',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      color: '#fff',
                      margin: 0,
                      padding: 0,
                      border: 0,
                      width: '100%',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff',
                      fontWeight: 'bold',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                      color: '#fff',
                      borderRadius: 8,
                      borderColor:
                        state.isFocused || state.isHovered
                          ? '#B420FF'
                          : '#2C2F48',
                      boxShadow:
                        state.isFocused || state.isHovered
                          ? '0 0 0 0.5px #B420FF'
                          : 'none',
                      paddingLeft: '1rem',
                      transition: 'border-color 0.2s ease',
                      '&:hover': {
                        borderColor: '#B420FF',
                        boxShadow: '0 0 0 0.5px #B420FF',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#9945ff'
                        : state.isFocused
                          ? '#393D5E'
                          : '#2C2F48',
                      color: state.isFocused ? '#B420FF' : '#fff',
                      cursor: 'pointer',
                      maxWidth: '97%',
                      padding: '0.5rem 1rem',
                      margin: '0.2rem 0',
                      marginLeft: '0.5rem',
                      marginRight: '0.5rem',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#393D5E',
                        paddingLeft: '1.5rem',
                        transition: 'all 0.3s ease',
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#9945ff',
                      color: '#fff',
                      borderRadius: 4,
                      padding: '0.2rem 0.5rem',
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: '#fff',
                      fontSize: '0.9rem',
                    }),
                  }}
                />
              </div>
            </div>
            {/* Select Role */}
            <div className="w-full flex flex-col justify-end">
              <span className="text-sm">Select a Role</span>
              <div className="w-full flex flex-col bg-second rounded-lg border border-third">
                <Select
                  options={dropdownRoles}
                  isMulti={false}
                  components={animatedComponents}
                  value={selectedRole}
                  onChange={(selectedOptions) => {
                    setSelectedRole(selectedOptions)
                    handleRoleChange(selectedOptions)
                  }}
                  placeholder="Select roles"
                  className="basic-multi-select"
                  classNamePrefix="select overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic"
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#2C2F48',
                      color: '#fff',
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#B420FF #2C2F48',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                      height: 'auto',
                      maxHeight: '100%',
                      color: '#fff',
                      alignItems: 'center',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      color: '#fff',
                      margin: 0,
                      padding: 0,
                      border: 0,
                      width: '100%',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff',
                      fontWeight: 'bold',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                      color: '#fff',
                      borderRadius: 8,
                      borderColor:
                        state.isFocused || state.isHovered
                          ? '#B420FF'
                          : '#2C2F48',
                      boxShadow:
                        state.isFocused || state.isHovered
                          ? '0 0 0 0.5px #B420FF'
                          : 'none',
                      paddingLeft: '1rem',
                      transition: 'border-color 0.2s ease',
                      '&:hover': {
                        borderColor: '#B420FF',
                        boxShadow: '0 0 0 0.5px #B420FF',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#9945ff'
                        : state.isFocused
                          ? '#393D5E'
                          : '#2C2F48',
                      color: state.isFocused ? '#B420FF' : '#fff',
                      cursor: 'pointer',
                      maxWidth: '97%',
                      padding: '0.5rem 1rem',
                      margin: '0.2rem 0',
                      marginLeft: '0.5rem',
                      marginRight: '0.5rem',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#393D5E',
                        paddingLeft: '1.5rem',
                        transition: 'all 0.3s ease',
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#9945ff',
                      color: '#fff',
                      borderRadius: 4,
                      padding: '0.2rem 0.5rem',
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: '#fff',
                      fontSize: '0.9rem',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          {/* Verification type */}
          <div className="flex lg:flex-row flex-col gap-5 transition-all duration-300 ease-in-out items-end justify-between lg:w-1/2 lg:pb-14">
            <Switch
              span="Verification type"
              isChecked={isChecked}
              handleCheckboxChange={() => setIsChecked(!isChecked)}
              labelLeft="Standard"
              labelRight="Captcha"
            />
          </div>
          <hr className="w-full border-third" />
          {/* Send Button */}
          <div className="w-full flex items-end justify-end">
            <Button
              onClick={saveClick}
              className="bg-main w-32 h-12"
              color="purple"
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}
export default AntiBotMembers
