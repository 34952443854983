import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Spinner,
} from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiPlusCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const DropdownChoose = ({ onGuildChange }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(0)
  const [servers, setServers] = useState(
    JSON.parse(localStorage.getItem('myServers')) || []
  )
  const [serverSelected, setServerSelected] = useState(
    localStorage.getItem('guildId') || null
  )

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value)
  }

  const handleChangeServer = (server) => {
    setOpen(0)
    localStorage.setItem('selectedServer', server.id)
    localStorage.setItem('guildId', server.id)
    setServerSelected(server.id)
    navigate('/dashboard')
    window.location.reload()
  }

  const handleSelectServer = () => {
    navigate('/select-server')
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <>
      <Menu
        className="bg-second text-white w-full rounded-lg cursor-pointer shadow-dark shadow-md bg-transparent hover:bg-transparent"
        open={menuOpen}
        handler={toggleMenu}
        animate={{
          mount: { y: -2 },
          unmount: { y: 0 },
        }}
      >
        <MenuHandler className="px-2 py-1.5 cursor-pointer w-full flex items-end bg-second hover:bg-basic border-2 border-basic rounded-lg">
          <div className="w-full flex flex-row items-end rounded-lg">
            <div className="bg-third rounded-full w-8 h-8">
              {serverSelected &&
              servers.find((server) => server.id === serverSelected)?.icon ? (
                <img
                  src={`https://cdn.discordapp.com/icons/${serverSelected}/${servers.find((server) => server.id === serverSelected).icon}`}
                  alt="Server Icon"
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <div className="w-8 h-8 rounded-full"></div>
              )}
            </div>
            <div className="w-full flex flex-col pl-1">
              <span className="text-white pr-2 pl-1 text-base font-normal w-[80%] truncate transition-all duration-300 ease-in-out group-hover:whitespace-normal group-hover:overflow-visible ">
                {servers?.find((server) => server.id === serverSelected)
                  ?.name || <Spinner className="w-8 h-8 text-main/50" />}
              </span>
              <span className="w-[80%] border-b border-main animate-pulse"></span>
            </div>
            <ChevronDownIcon
              className={`w-4 h-4  right-4 top-1/2 -translate-y-1/2 transition-transform duration-300 ${!menuOpen ? 'rotate-0' : 'rotate-180 text-main'}`}
            />
          </div>
        </MenuHandler>
        <MenuList className="bg-second hover:bg-second border border-basic w-full max-w-[280px]  max-h-60 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic -mt-1 p-0 shadow-dark shadow ">
          {/* {servers?.map((server, index) => ( */}
          {servers
            ?.filter((server) => server.isBotInstalled)
            .map((server, index) => (
              <MenuItem
                key={index}
                className="p-0 bg-transparent hover:bg-transparent transition-all duration-300"
              >
                <div
                  className="w-full flex items-center transition-all duration-300 ease-in-out gap-x-2 p-2 hover:bg-basic hover:pl-3"
                  onClick={() => handleChangeServer(server)}
                >
                  <div className="bg-basic rounded-full flex-shrink-0">
                    {server.icon ? (
                      <img
                        src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}`}
                        alt="Server Icon"
                        className="w-8 h-8 rounded-full border border-basic shadow-dark shadow"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full border border-basic shadow-dark shadow flex items-center justify-center text-white font-thin text-xs tracking-tighter">
                        {server.name.substring(0, 3)}
                      </div>
                    )}
                  </div>
                  <span className="text-white text-base font-normal w-auto truncate transition-all duration-300 ease-in-out capitalize">
                    {server.name}
                  </span>
                </div>
              </MenuItem>
            ))}
          {/* -------- map termina aqui -------- */}
          <hr className="my-0 border-main mx-2" />
          <MenuItem className="group-hover:text-main transition-all duration-300 border-none shadow shadow-dark group p-0 bg-transparent hover:bg-transparent">
            <div
              className="w-full h-full flex items-center gap-x-2  hover:bg-basic px-2 py-3"
              onClick={handleSelectServer}
            >
              <BiPlusCircle
                size={26}
                className="group-hover:text-main text-white transition-all duration-300"
              />
              <span className="w-full h-full text-white tracking-wider group-hover:text-main text-xs text-center font-bold transition-all duration-300 uppercase">
                Add new server
              </span>
            </div>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

export default DropdownChoose
