import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated, isServerSelected } from './authUtils'

const FullyProtectedRoute = ({ element }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/" />
  }

  if (!isServerSelected()) {
    return <Navigate to="/select-server" />
  }

  return element
}

export default FullyProtectedRoute
