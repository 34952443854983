import React, { useEffect, useState } from 'react'
import ServerCard from './_components/ServerCard'

const ChooseServer = () => {
  const [serverBot, setServerBot] = useState([])
  const [isFetched, setIsFetched] = useState(false)

  //Call API to get all servers
  const fetchServers = () => {
    if (isFetched) return
    const userData = JSON.parse(localStorage.getItem('userData'))
    const accessToken = userData.tokenInfo.accessToken
    fetch(`${process.env.REACT_APP_API_BASE_URL}/guilds`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        accessToken: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setServerBot(data)
        setIsFetched(true)
      })
      .catch((error) => {
        console.error('Error fetching server list:', error)
      })
  }

  useEffect(() => {
    fetchServers()
  }, [])

  return (
    <>
      <section className="h-full w-full text-white py-10">
        <h1 className="font-medium text-4xl pb-10 flex">Select a server</h1>
        <ServerCard serversList={serverBot} />
      </section>
    </>
  )
}

export default ChooseServer
