import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'
import Header from './Header/HeaderAdmin'
import MobileSidebar from './Sidebar/MobileSidebar'
import Sidebar from './Sidebar/Sidebar'

const AdminMain = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <>
      <div className="flex flex-col h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit ">
        <div className="flex flex-col md:flex-row overflow-hidden w-full h-full">
          {isMobile ? (
            <MobileSidebar />
          ) : (
            <div className="w-[300px]">
              <Sidebar />
            </div>
          )}

          <div className=" flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner ">
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminMain
