import { Alert, Card, Tooltip, Typography } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { FaCrown } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import DropdownChoose from '../ChooseServer/DropdownChoose'
import Tip from '../components/ui/tip'
import Notice from './Notice'
import dataSidebar from './dataSidebar'

import logo from '../../img/200x60.png'
import avatar from '../../img/AvatarTektools.png'

const Sidebar = () => {
  const [open, setOpen] = React.useState(0)
  const [openAlert, setOpenAlert] = React.useState(true)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value)
  }

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  const [data, setData] = useState([])
  useEffect(() => {
    const filteredData = dataSidebar.filter(
      (item) =>
        localStorage.getItem('guildId') === '1216871846067179620' || item.enable
    )
    setData(filteredData)
  }, [])

  return (
    <>
      <Card
        color="transparent"
        className="m-0 h-full w-full shadow-2xl shadow-main text-white rounded-none border-r-2 border-main gap-3"
      >
        <div className='flex w-full justify-center pt-5'>
          <a href="/" className="flex gap-x-5">
            <img className="lg:h-12 h-8 w-auto" src={avatar} alt="Avatar" />
            <img className="lg:h-12 h-8 w-auto" src={logo} alt="Tektools" />
          </a>
        </div>
        <hr className="my-3 border-main shadow-main shadow" />
        <div className="w-full px-2 z-30">
          <DropdownChoose />
        </div>
        <div className="flex flex-col gap-y-px pt-5 ">
          {data?.map((data, index) => (
            <NavLink
              key={index}
              to={data.link}
              className={({ isActive }) =>
                `w-full h-12 flex items-center gap-2 pl-4 font-medium transition-all duration-500 ${isActive ? 'bg-gradient-to-l from-main hover:from-main/90 to-transparent text-white pl-6 shadow-lg shadow-main/20' : 'hover:pl-5 bg-none hover:bg-main/10'}`
              }
            >
              <div className="w-full flex gap-x-2">
                <div className="h-5 w-5">{data.icon}</div>
                {data.name}
              </div>
              <div>
                {data.premium && (
                  <div className="bg-orange-50/10 rounded-full px-2 py-1">
                    <FaCrown size={14} className="text-xs text-orange-200" />
                  </div>
                )}
              </div>
              <div className="pr-2">
                {data.tip && <Tip tip={data.tip} placement="right-end" />}
              </div>
            </NavLink>
          ))}
        </div>
        <div className="mt-auto w-full hidden">
          <div className="p-3 w-full">
            <Alert
              open={openAlert}
              className="bg-dark p-5  w-full h-[350px] shadow-xl shadow-main/15"
              onClose={() => setOpenAlert(false)}
            >
              <Notice />
            </Alert>
          </div>
        </div>
      </Card>
    </>
  )
}

export default Sidebar
