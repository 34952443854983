import React, { useState, useEffect } from 'react'
import { InputNumber } from '../../components/ui/input'

const ConditionsRewards = ({
  onRewardsPerDaysChange,
  onMinimumWithdrawBalanceChange,
  onMinimumWithdrawDaysChange,
}) => {
  const [rewardsPerDays, setRewardsPerDays] = useState(0)
  const [minimumWithdrawBalance, setMinimumWithdrawBalance] = useState(0)
  const [minimumWithdrawDays, setMinimumWithdrawDays] = useState(0)

  useEffect(() => {
    onRewardsPerDaysChange(rewardsPerDays)
    onMinimumWithdrawBalanceChange(minimumWithdrawBalance)
    onMinimumWithdrawDaysChange(minimumWithdrawDays)
  }, [
    rewardsPerDays,
    minimumWithdrawBalance,
    minimumWithdrawDays,
    onRewardsPerDaysChange,
    onMinimumWithdrawBalanceChange,
    onMinimumWithdrawDaysChange,
  ])

  return (
    <>
      <div className="w-full flex flex-col lg:flex-row gap-5 items-end">
        {/* Rewards per days */}
        <InputNumber
          label="Define how much a holder gets rewarded per day of staking"
          maxSize={true}
          onChange={(value) => setRewardsPerDays(value)}
        />
        {/* Minimum withdraw balance */}
        <InputNumber
          label="Define a minimum withdraw balance"
          maxSize={true}
          onChange={(value) => setMinimumWithdrawBalance(value)}
        />

        {/* Minimum amount of days */}
        <InputNumber
          label="Define a minimum withdraw amount of days"
          maxSize={true}
          onChange={(value) => setMinimumWithdrawDays(value)}
        />
      </div>
    </>
  )
}

export default ConditionsRewards
