import React from 'react'
import Header from '../../components/Header/Header'
import ChooseServer from './ChooseServer'

const ChooseMain = () => {
  return (
    <div className="flex flex-col h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit">
      <Header />
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner w-full">
        <div className="container mx-auto">
          <ChooseServer />
        </div>
      </div>
    </div>
  )
}

export default ChooseMain
