export const getRoles = async () => {
  try {
    const roles = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('guildId')}`
    )
    const data = await roles.json()
    return data
  } catch (error) {
    console.error('Error fetching roles:', error)
    return null
  }
}
