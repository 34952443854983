export const dataPrices = [
  {
    name: 'Basic',
    price: { free: 'Free' },
    included: [
      'Anti-bot member verification',
      'Crowd sourcing security system',
      'Engagement system',
      'Twitter Raid to earn',
      'Whitelist Wallets collection',
      'Server Stats',
      'Ticket System',
      'Mini Games',
      'Anonymous poll system',
      'Giveaways',
      'Basic support',
    ],
    excluded: ['Holder Verification', 'Staking System'],
  },
  {
    name: 'Lite',
    price: {
      monthly: '$ 50/m',
      semesterly: '$ 50/m',
      yearly: '$ 45/m',
    },
    description: 'Everything in Basic +',
    included: [
      'Holder Verification',
      'Roles Per Quantity(Limited)',
      'Roles Per Trait(Limited)',
      'Sales/Listings Notifications',
      'Twitter Raid to earn with Tokens',
      'Discord Backups',
      'Advanced support',
    ],
    excluded: ['Staking System', 'Airdrop System', 'Revenue Sharing System'],
  },
  {
    name: 'Premium',
    price: {
      monthly: '$ 100/m',
      semesterly: '$ 85/m',
      yearly: '$ 70/m',
    },
    description: 'Everything in Lite +',
    included: [
      'Holder Verification',
      'Roles Per Quantity',
      'Roles Per Trait',
      'Staking System',
      'Airdrop System',
      'Revenue Sharing System',
      'Holder Snapshots',
      'Advanced support',
    ],
    excluded: [],
  },
]
