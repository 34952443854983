export const authenticateUser = async (code) => {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/auth`
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Code: code,
    },
  })

  if (!response.ok) {
    throw new Error('Erro na resposta da API')
  }

  const data = await response.json()
  const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000
  localStorage.setItem('userData', JSON.stringify(data))
  localStorage.setItem('expirationTime', expirationTime)
  return data
}

export const checkTokenValidity = () => {
  const expirationTime = localStorage.getItem('expirationTime')
  if (!expirationTime || new Date().getTime() > expirationTime) {
    handleLogout()
  }
}

export const handleLogout = () => {
  if (localStorage.getItem('userData')) {
    localStorage.clear()
    window.location.replace('/')
  }
}

const timestamp = Number(localStorage.getItem('expirationTime'))
const date = new Date(timestamp)
console.log(date.toLocaleString())

export const setupAutoLogout = () => {
  const expirationTime = localStorage.getItem('expirationTime')
  if (expirationTime) {
    const timeLeft = expirationTime - new Date().getTime()
    const timer = setTimeout(() => {
      handleLogout()
    }, timeLeft)
    return () => clearTimeout(timer)
  }
}

export const isAuthenticated = () => {
  const userData = localStorage.getItem('userData')
  const expirationTime = Number(localStorage.getItem('expirationTime'))
  return userData && expirationTime && new Date().getTime() < expirationTime
}

export const isServerSelected = () => {
  const guildId = localStorage.getItem('guildId')
  return !!guildId // Retorna true se guildId existir
}
