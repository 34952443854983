import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiLeftArrow } from 'react-icons/bi'
import { MdAdd } from 'react-icons/md'
import CreateWallet from './CreateWallet'
import LoadWallet from './LoadWallet'

const WalletManagement = () => {
  const [walletCreated, setWalletCreated] = useState(false)
  const [newWallet, setNewWallet] = useState(false)
  const [wallets, setWallets] = useState([])

  const handleWallet = (wallets) => {
    setWallets(wallets)
  }

  const handleWalletCreated = () => {
    setWalletCreated((prev) => !prev)
  }

  return (
    <>
      <div className="container mx-auto h-full text-white py-5">
        <div className="flex flex-col gap-y-5">
          {/* header */}
          <div className="flex justify-between items-start">
            <h1 className="text-4xl pb-14 font-bold">Wallet Management</h1>
            {newWallet ? (
              <Button
                onClick={() => {
                  setNewWallet((prev) => !prev)
                }}
                className="flex items-center gap-x-2 font-semibold text-base tracking-wider "
                variant="filled"
                size="lg"
                color="purple"
              >
                <BiLeftArrow />
                Back
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setNewWallet((prev) => !prev)
                }}
                className="flex items-center gap-x-2 font-semibold text-base tracking-wider "
                variant="filled"
                size="lg"
                color="purple"
              >
                <MdAdd />
                Add Wallet
              </Button>
            )}
          </div>
        </div>
        {/* body */}
        <div className="flex flex-col gap-y-5">
          {newWallet && <CreateWallet onWalletCreated={handleWalletCreated} />}
          <LoadWallet
            walletCreated={walletCreated}
            onWalletCreated={handleWallet}
          />
        </div>
      </div>
    </>
  )
}

export default WalletManagement
