import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../components/ui/button'
import SectionTokenCreation from './SectionTokenCreation'
import SwitchTokenContact from './_components/SwitchTokenContact'

const TokenCreation = () => {
  const [isCheckedContact, setIsCheckedContact] = useState(false)
  const [tokenInfo, setTokenInfo] = useState(null)
  const handleTokenInfoChange = (info) => {
    setTokenInfo(info)
  }

  const handleSaveToken = async () => {
    if (!tokenInfo || Object.values(tokenInfo).some((value) => !value)) {
      toast.error('Please fill in all fields before saving the token.')
      return
    }
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/token`
    toast.promise(
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tokenInfo),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('API response error')
          }
          return response.json()
        })
        .then((data) => {
          toast.success('Token created successfully!')
          window.location.reload()
        })
        .catch((error) => {
          console.error('Error sending to the API:', error)
          toast.error(`Error creating token: ${error.message}`)
        }),
      {
        pending: 'Creating token...',
        success: 'Token created successfully!',
        error: 'Error creating token!',
      }
    )
  }

  const handleClickCancel = () => {
    toast.info('Ops')
    window.location.reload()
  }

  return (
    <section className="container mx-auto h-full text-white py-5">
      <h1 className="text-4xl pb-14 font-bold ">SPL - Create Token</h1>
      <div className="flex flex-col gap-y-5 items-center">
        <SectionTokenCreation onTokenInfoChange={handleTokenInfoChange} />
        {/* Seção de Contato */}
        <div className="py-5 hidden">
          <SwitchTokenContact
            isChecked={isCheckedContact}
            setIsChecked={setIsCheckedContact}
          />
        </div>
        <div className="flex flex-row py-3 ">
          {isCheckedContact && <InputTokenContactsDiscord />}
          {isCheckedContact && <InputTokenContactsDiscord />}
          {isCheckedContact && <InputTokenContactsDiscord />}
        </div>
      </div>
      <hr className="h-px mb-5 bg-gray border-0" />
      <div className="flex justify-between pb-10 ">
        <Button
          onClick={handleClickCancel}
          type="reset"
          className="w-40 bg-basic border uppercase  rounded-lg items-center justify-center h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide transition duration-300"
        >
          Cancelar
        </Button>
        <Button
          className="uppercase bg-accentMain rounded-lg items-center justify-center w-36 h-12 text-center text-base font-bold hover:bg-accentHouver tracking-wide transition duration-300"
          onClick={handleSaveToken}
          type="button"
        >
          Save
        </Button>
      </div>
    </section>
  )
}

export default TokenCreation
