import token from '../../../img/TOKEN.png'

export default function ImgToken() {
  return (
    <>
      <img
        src={token}
        alt="NFT"
        className="rounded-l-lg object-cover h-[300px] lg:h-[500px]"
      />
    </>
  )
}
