import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import Tektolls from '../../img/TektoolsFace.png'

const LoadVotingSystem = () => {
  const [pollList, setPollList] = useState([])

  useEffect(() => {
    const pollList = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/polls?guildId=${localStorage.getItem('selectedServer')}`
      )
        .then((response) => response.json())
        .then((data) => {
          setPollList(data)
        })
        .catch((error) => {
          console.error('Error fetching poll list:', error)
        })
    }
    pollList()
  }, [])

  const handleRepeat = (poll) => {
    const selectedPoll = {
      guildId: localStorage.getItem('selectedServer'),
      roles: [],
      channelId: poll.PollChannelId,
      question: poll.PollQuestion,
      options: poll.PollAnswers.map((answer) => answer.Option),
      duration: poll.PollSelectedWindow,
    }
    console.log('Refazer Poll:', selectedPoll)
  }

  return (
    <>
      <div className="flex flex-wrap-reverse gap-5">
        {pollList.map((poll, index) => (
          <div className="w-80 h-[400px] rounded-lg" key={index}>
            <Card className="w-full h-full bg-third rounded-lg">
              <div className="absolute h-10 w-full bg-second top-0 rounded-t-lg"></div>
              <div className="absolute h-4 w-full bg-second bottom-0 rounded-b-lg"></div>
              <div className="absolute h-full w-4 bg-second right-0 rounded-l-lg"></div>
              <div className="absolute h-full w-4 bg-second left-0 rounded-r-lg"></div>

              <CardBody className="h-full  flex flex-col">
                {/* Headers */}
                <CardHeader className="relative flex items-center gap-x-2 bg-second p-2 rounded-b-xl rounded-t-none shadow-lg mb-2">
                  <img src={Tektolls} className="w-10 h-10 rounded-full" />
                  <span>Progress... </span>
                </CardHeader>
                {/* Poll Question */}
                <div className="max-h-16 h-16 bg-basic rounded-md hover:bg-none mb-3 overflow-hidden hover:cursor-pointer group shadow-xl shadow-dark/40 transition-all duration-500">
                  <div className="w-full h-full flex flex-col">
                    <span className="bg-main px-2 py-1 rounded-lg h-fit w-fit text-xs text-center group-hover:hidden transition-all duration-500 m-1">
                      Poll question
                    </span>
                    <span className="w-full h-full truncate group-hover:hidden pl-3 transition-all duration-500 py-1 bg-second">
                      {poll.PollQuestion}
                    </span>
                  </div>
                  <div className="absolute flex flex-col z-10 h-2/5 top-0 left-1/2 w-60 -translate-x-1/2 justify-center bg-second px-2 py-5 gap-y-0.5 opacity-0 hover:opacity-100 transition-opacity duration-300 max-h-full shadow-lg shadow-dark">
                    <div className="flex items-center gap-x-2">
                      <img src={Tektolls} className="w-10 h-10 rounded-full" />
                      <span>Progress... </span>
                    </div>
                    <span className="w-full break-words">
                      {poll.PollQuestion}
                    </span>
                  </div>
                </div>
                {/* Options Poll */}
                <div className="shadow-xl shadow-dark/40 rounded-md">
                  <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic w-full bg-second h-[90px] gap-1 rounded-t-sm text-sm border border-basic flex flex-col gap-y-1">
                    <div className="w-full bg-basic p-1">
                      <span className="bg-main px-2 py-0.5 rounded-lg h-fit w-fit text-xs text-center group-hover:hidden transition-all duration-500">
                        Poll question
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {poll.PollAnswers.map((answer, index) => (
                        <div
                          className="bg-third px-2 py-0.5 rounded-lg h-fit w-fit"
                          key={index}
                        >
                          {answer.Option}
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Duration for voting */}
                  <div className="flex gap-x-1 px-4 text-xs py-1 shadow-sm justify-end bg-basic border border-basic rounded-b-sm">
                    <span>End in</span>
                    <span>
                      {new Date(poll.ExpirationTimeUTC).toLocaleString()}
                    </span>
                  </div>
                </div>
                {/* Participants */}
                <div className="flex gap-x-2 mt-3 ">
                  <div className="flex flex-col items-center rounded-lg w-full shadow-xl shadow-dark/40">
                    <span className="text-xs bg-basic w-full px-3 py-2 rounded-t-lg text-center">
                      Participants
                    </span>
                    <span className="bg-second w-full p-1 rounded-b-lg items-center flex justify-center h-full">
                      {poll.MembersVoted.length}
                    </span>
                  </div>
                  <div className="flex flex-col items-center rounded-lg w-full shadow-xl shadow-dark/40">
                    <span className="text-xs bg-basic w-full py-2 rounded-t-lg text-center uppercase">
                      Poll winner
                    </span>
                    <div className="bg-second w-full p-1 rounded-b-lg items-center flex justify-center">
                      <span className="bg-third px-2 py-0.5 rounded-lg h-full w-fit">
                        {poll.PollAnswers.length > 0
                          ? poll.PollAnswers.reduce(
                              (max, answer) =>
                                answer.Count > max.Count ? answer : max,
                              poll.PollAnswers[0]
                            ).Count > 0
                            ? poll.PollAnswers.reduce(
                                (max, answer) =>
                                  answer.Count > max.Count ? answer : max,
                                poll.PollAnswers[0]
                              ).Option
                            : 'No winner'
                          : 'No winner'}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
              {/* Footer */}
              <div className="flex absolute -bottom-2 -right-2 justify-end p-2 w-full ">
                <div className="bg-second w-[128px] rounded-l-xl flex justify-end pr-4">
                  <Button
                    color="purple"
                    onClick={() => handleRepeat(poll)}
                    className="bg-main px-2 m-2 w-24"
                  >
                    Repeat
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}

export default LoadVotingSystem
