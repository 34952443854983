import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import CreateRevenueSharing from './CreateRevenueSharing'
import LoadRevenueSharing from './LoadRevenueSharing'
import { BiLeftArrowAlt } from 'react-icons/bi'

const RevenueSharing = () => {
  const [revSharingInfo, setRevSharindInfo] = useState()
  const [newRevSharing, setNewRevSharing] = useState(false)
  return (

    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="flex justify-between items-center container mx-auto pb-10">
        <h1 className="text-4xl font-bold">Revenue Sharing</h1>
        <Button
          color="purple"
          className="flex max-w-40 min-w-14 h-12 font-bold px-4 py-3 items-center justify-center "
          onClick={() => setNewRevSharing(true)}
        >
          {!newRevSharing ? 'New Revenue Sharing' : <BiLeftArrowAlt size={20} />}
        </Button>
      </div>
      {!newRevSharing ? (
        <CreateRevenueSharing
          loadRevSharing={revSharingInfo}
          returnBotton={() => setNewRevSharing(false)}
        />
      ) : (
        <LoadRevenueSharing onListRevSharing={setRevSharindInfo} />
      )}

    </section>
  )
}

export default RevenueSharing
